<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm">
      <template v-slot:header>
        {{ headerText }}
      </template>

      <template v-slot:body>
        <div class="mx-3 font-reduce-1" v-if="mode === 'confirmRotateCredentials'">
          <h5 class="mb-3">Just a Quick Confirmation</h5>
          Rotating the Flashing Appliance's credentials will lock it until reconfigured via QR Code.
          <br /><br />
          Before Proceeding:
          <ul>
            <li>Have the Appliance physically with you.</li>
            <li>Power it on and connect to the internet.</li>
            <li>Power on the hand scanner.</li>
          </ul>
          Pressing 'Rotate Credentials' again is dangerous. Only do so when you are ready to proceed.
        </div>
        <div class="mx-3 font-reduce-1" v-else-if="mode === 'credentialsRotated'">
          <div class="mb-3">
            <fa icon="check" class="button-icon me-2" />
            <b>Appliance #{{  formData.serial }}'s credentials rotated.</b>
          </div>
          <div class="mb-3">
            With the Appliance powered on and connected to the internet, scroll through the Main Menu, select 'Scan QR' and, when prompted, scan this QR Code.
          </div>
          <div style="margin-left:80px;" class="my-3">
            <QR v-if="renderValue" :value="renderValue" :size="180" class="qr-code-bw qr-code-bw-framed mb-3" render-as="svg" level="H" />
          </div>
          <div class="mb-3">
            This code will expire in 5 minutes, and cannot be accessed after this window is closed.
          </div>
          <div class="mb-3">
            If you accidentally close this window, or do not update the Flashing Appliance before the code expires, you can rotate the credentials again to get a new code.
          </div>
        </div>
        <form ref="flashingApplianceForm" @submit.prevent="handleSubmit" autocomplete="off" style="margin-top:-8px; margin-bottom:-12px" v-else>

          <TextInputRow label="Serial"
            v-model="formData.serial"
            labelClass="label-required width-135"
            :required="true"
            placeholder="001"
            maxlength="3"
            dataType="integer"
            inputStyle="width: 65px;"
          />

          <TextInputRow label="Description"
            v-model="formData.description"
            placeholder="Appliance Description"
            maxlength="28"
            dataType="description"
            labelClass="width-135"
            inputStyle="width: 215px;"
          />

          <SelectRow label="Target Firmware"
            labelClass="width-135"
            selectStyle="width: 215px;"
            v-model="formData.swVersionTarget"
          >
            <template v-slot:options>
              <option value="">-- Select Version --</option>
              <option value="default">Default for Model</option>
              <option value="1.1.0">1.1.0</option>
              <option value="1.2.0">1.2.0</option>
              <option value="1.3.0">1.3.0</option>
              <option value="1.4.0">1.4.0</option>
              <option value="1.5.0">1.5.0</option>
            </template>
          </SelectRow>

          <TextLabelRow label="Reported Firmware"
            labelClass="width-135 subtle"
            valueClass="ms-1 subtle"
            :value="flashingAppliance.swVersionCurrent"
            v-if="flashingAppliance && flashingAppliance.swVersionCurrent"
          />

          <TextAreaRow label="Notes"
            labelClass="width-135"
            v-model="formData.notes"
            placeholder="Enter any info about this Device that Agrology may find useful in the future."
            class="pb-2"
            inputStyle="height: 77.5px; width: 215px;"
          />

          <LastModifiedRow :ts="formData.lastModified" :author="formData.lastModifiedBy" v-if="formData.lastModified" />

        </form>
      </template>
      <template v-slot:footer>
        <div class="col-auto ms-auto">
          <button class="btn btn-red ms-3" @click="showConfirmRotateCredentials()" :disabled="isLoading" v-if="!isCreate && !mode">Rotate Credentials</button>
          <button class="btn btn-red ms-3" @click="doRotateCredentials()" :disabled="isLoading" v-if="!isCreate && mode === 'confirmRotateCredentials'">Rotate Credentials</button>
          <button class="btn btn-green ms-3" @click="updateFlashingAppliance()" :disabled="isLoading || !formData.serial" v-if="!isCreate && !mode">Update</button>
          <button class="btn btn-green ms-3" @click="createFlashingAppliance()" :disabled="isLoading || !formData.serial" v-else-if="isCreate">Submit</button>
          <button class="btn btn-blue ms-3" @click.prevent="close()" v-if="mode === 'credentialsRotated'">Close</button>
          <button class="btn btn-blue ms-3" @click.prevent="close()" v-else>Cancel</button>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import FleetDataService from '@/services/fleet/FleetDataService'

import Modal from '@/components/Modal.vue'
import LastModifiedRow from '@/components/forms/LastModifiedRow.vue'
import TextAreaRow from '@/components/forms/TextAreaRow.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import TextLabelRow from '@/components/forms/TextLabelRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import QR from '@/app/qrcode.js'

export default {
  name: 'flashapp-add-update',
  props: ['flashingAppliance'],
  data () {
    return {
      isBlocking: false,
      formData: {},
      isCreate: false,
      isLoading: false,
      mode: '',
      code: ''
    }
  },
  computed: {
    renderValue () {
      if (this.flashingAppliance) {
        return `rotateCredentials|${this.flashingAppliance.id}|${this.flashingAppliance.serial}|${this.code}`
      }
      return ''
    },
    headerText () {
      if (this.flashingAppliance) {
        return 'Edit Flashing Appliance'
      }
      return 'Add New Flashing Appliance'
    }
  },
  methods: {
    reset () {
      this.mode = ''
      if (this.flashingAppliance) {
        this.formData = { ...this.flashingAppliance }
      } else {
        this.isCreate = true
        this.formData = {}
      }
    },
    showConfirmRotateCredentials () {
      if (!this.isCreate) {
        this.mode = 'confirmRotateCredentials'
      }
    },
    doRotateCredentials () {
      if (!this.isCreate) {
        this.rotateFlashingAppliance()
      }
    },
    show () {
      this.reset()
      this.isBlocking = true
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    rotateFlashingAppliance () {
      this.isLoading = true
      FleetDataService.rotateFlashingApplianceApiKey(this.flashingAppliance.id)
        .then((response) => {
          if (response && response.data && response.data.id) {
            this.code = response.data.id
          }
          this.mode = 'credentialsRotated'
        })
        .catch(e => {
          let message = 'Flashing Appliance Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateFlashingAppliance () {
      this.isLoading = true
      FleetDataService.updateFlashingAppliance(this.flashingAppliance.id, this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Flashing Appliance Updated')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Flashing Appliance Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    createFlashingAppliance () {
      this.isLoading = true
      FleetDataService.createFlashingAppliance(this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Flashing Appliance Created')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Flashing Appliance Creation Failed'
          if (e.response && e.response.data && e.response.data.message) {
            if (e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
              message += ': Flashing Appliance Already Exists'
            } else {
              message += `: ${e.response.data.message}`
            }
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  components: { Modal, TextInputRow, TextLabelRow, SelectRow, TextAreaRow, LastModifiedRow, QR }
}
</script>
