<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm">
      <template v-slot:header>
        {{ headerText }}
      </template>

      <template v-slot:body>
        <div class="mx-3" v-if="mode === 'confirm-publish'">
          <h5 class="mb-3">Just a Quick Confirmation</h5>
          Publishing this Version will make it available to be run by Jobs, while permanently locking the Container Tag and Script File settings.
          <br /><br />
          If you need to update the Script once the Version is published, create a new Version.
          <br /><br />
          Press 'Publish' again to proceed.
        </div>
        <div class="mx-3" v-else-if="mode === 'confirm-duplicate'">
          <TextInputRow label="New Version ID"
            v-model="dupeVersionName"
            placeholder="v1.0.1, test-version, etc."
            :required="true"
            dataType="version"
            labelClass="width-155"
            inputStyle="width: 160px !important;"
          />
        </div>
        <div class="ms-2 me-3" v-else-if="script">
          <TextLabelRow label="Script" :value="script.name" valueClass="notable" />
          <TextInputRow label="Version ID"
            v-model="formData.version"
            :readonly="!isCreate"
            :inputClass="{'subtle-info': !isCreate}"
            placeholder="v1.0.1, test-version, etc."
            :required="true"
            dataType="version"
          />
          <TextInputRow label="Description" v-model="formData.description" placeholder="Human-Readable Description" dataType="description" />

          <hr class="info-sep mb-3" />

          <SelectRow label="Container Tag"
            v-model="formData.containerTag"
            :readonly="!isCreate && (version && version.published)"
            :disabled="!isCreate && (version && version.published)"
            :inputClass="{'subtle-info': !isCreate || (version && version.published)}"
            :required="true"
          >
            <template v-slot:options>
              <option value="">-- Select an Container --</option>
              <Options />
            </template>
          </SelectRow>
          <FormFieldRow label="Script File" v-if="version && version.scriptURL">
            <template v-slot:input>
              <div style="height:32px; margin-top: 4px;">
                <span class="subtle">
                  <fa icon="check" class="flash-icon-svg me-2" />
                  Uploaded
                </span>
              </div>
            </template>
          </FormFieldRow>
          <FormFieldRow label="New Script File" v-if="isCreate || (version && !version.published)">
            <template v-slot:input>
              <div style="height:32px;margin-top: 3px;">
                <button class="btn btn-short" v-if="displayFileName === 'Attach a Script File'">
                  <label title="Select a Script" for="scriptFile">{{ displayFileName }}</label>
                </button>
                <div style="height:32px;margin-top: 3px;" v-else>
                  <label class="font-reduce-2 me-3">{{ displayFileName }}</label>
                  <button class="btn btn-short px-2">
                    <label title="Select a Script" for="scriptFile">Change</label>
                  </button>
                </div>
                <input class="d-none" type="file" id="scriptFile" @change="filesChanged($event)" accept=".py,.sh,.rb,.zip" />
              </div>
            </template>
          </FormFieldRow>

          <hr class="info-sep mb-3 mt-0" />

          <div>
            Secrets injected as ENV's:
            <ul v-if="secrets" class="info-pane-list ps-1">
              <li v-for="(s, i) in secrets" :key="i" class="my-2">
                <input type="text" class="form-input d-inline font-reduce-2" style="width:140px" v-model="secrets[i][0]" placeholder="ENV_NAME" />
                <span class="mx-2">=</span>
                <select class="form-select d-inline font-reduce-2" style="width:160px"  v-model="secrets[i][1]">
                  <option value="">-- Select Secret --</option>
                  <ModelingSecrets />
                </select>
                <a @click="removeSecret(i)" class="normal ms-1">
                  <fa icon="xmark" class="icon-text-link" />
                </a>
              </li>
            </ul>
            <div class="mt-2 mb-3">
              <span class="ms-2 me-1 subtle" v-if="!secrets">
                None
              </span>
              <a @click="newSecret" class="ms-2 normal">Add a Secret</a>
            </div>
            <div><span class="extra-subtle font-reduce-2 ms-2">Suggested ENV Name: AG_INTERNAL_API_KEY</span></div>
          </div>

          <div v-if="!isCreate && version && version.published" class="text-center subtle-info">
            <hr class="mb-3" />
            <span>This version has been published, so the Container Tag and Script File are locked.</span>
          </div>

          <div v-if="!isCreate && version && (!version.validated)" class="text-center subtle-info font-reduce-1 mb-3">
            <hr class="mb-3" />
            <span>This Script Version has not yet been validated, so it can't run as a job. Start a Test Run, and if it succeeds, the Version will be marked as Valid.</span>
          </div>

        </div>
      </template>

      <template v-slot:footer>
        <button class="btn ms-2" v-if="!version" @click="createVersion()" :disabled="isLoading || !formData.version || !formData.containerTag">
          Create Script Version
        </button>
        <button class="btn ms-2" v-if="version && mode === 'confirm-duplicate'" @click="duplicateVersion()" :disabled="isLoading || !dupeVersionName">
          Create Duplicate
        </button>
        <button class="btn ms-2" v-if="version && mode === ''" @click="duplicateVersion()" :disabled="isLoading">
          Duplicate
        </button>
        <button
          class="btn ms-2" :class="{'btn-red': mode === 'confirm-publish'}"
          v-if="version && version.validated && !version.published && (mode === '' || mode === 'confirm-publish')"
          @click="confirmPublishVersion()"
          :disabled="isLoading"
        >
          Publish
        </button>
        <button class="btn ms-2" v-if="version && mode === ''" @click="updateVersion()" :disabled="isLoading">
          Update
        </button>
        <button class="btn btn-blue ms-2" @click="close()">
          Cancel
        </button>
      </template>
    </Modal>
  </transition>
</template>

<script>
import Modal from '@/components/Modal.vue'
import TextLabelRow from '@/components/forms/TextLabelRow.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import FormFieldRow from '@/components/forms/FormFieldRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import Options from '@/components/selectOptions/ModelingJobContainerTags.vue'
import ModelingSecrets from '@/components/selectOptions/ModelingSecrets.vue'

import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import ModelingDataService from '@/services/ModelingDataService'

export default {
  data () {
    return {
      isBlocking: false,
      formData: {},
      dupeVersionName: '',
      isCreate: false,
      selectedFileName: '',
      mode: '',
      secrets: null,
      oldRoute: null,
      isLoading: false
    }
  },
  props: ['script', 'version'],
  components: { Modal, TextLabelRow, TextInputRow, SelectRow, FormFieldRow, ModelingSecrets, Options },
  computed: {
    displayFileName () {
      if (this.selectedFileName) {
        return this.selectedFileName
      }
      return 'Attach a Script File'
    },
    headerText () {
      if (this.mode === 'confirm-duplicate') {
        return 'Duplicate Script Version'
      } else if (this.version) {
        return 'Edit Script Version'
      }
      return 'Add New Script Version'
    },
    secretsCSV () {
      if (!this.secrets || this.secrets.length === 0) {
        return ''
      }
      const out = []
      for (const i in this.secrets) {
        if (this.secrets[i].length !== 2) {
          continue
        }
        out.push(`${this.secrets[i][0]}=${this.secrets[i][1]}`)
      }
      return out.join(',')
    }
  },
  methods: {
    parseSecrets () {
      if (this.formData && this.formData.secrets) {
        const out = []
        const items = this.formData.secrets.split(',')
        for (const item of items) {
          const parts = item.split('=')
          if (parts.length === 2) {
            out.push(parts)
          }
        }
        this.secrets = out
      }
    },
    removeSecret (i) {
      this.secrets.splice(i, 1)
    },
    newSecret () {
      if (!this.secrets) {
        this.secrets = []
      }
      this.secrets.push(['', ''])
    },
    confirmPublishVersion () {
      if (this.mode !== 'confirm-publish') {
        this.mode = 'confirm-publish'
      } else {
        this.isLoading = true
        ModelingDataService.publishScriptVersion(this.script.name, this.formData.version)
          .then(() => {
            Reloadables.requestReload()
            HelpContent.setTimedFlashMessage(`Script Version ${this.formData.version} is Published and can be used in Jobs.`)
            this.close()
          })
          .catch(e => {
            let message = `Failed to Publish Version ${this.formData.version}`
            if (e.response && e.response.data && e.response.data.message) {
              message += `: ${e.response.data.message}`
            }
            HelpContent.setFlashMessage(message, true)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    duplicateVersion () {
      if (this.mode !== 'confirm-duplicate' || !this.dupeVersionName) {
        this.dupeVersionName = ''
        this.mode = 'confirm-duplicate'
      } else {
        this.formData.secrets = this.secretsCSV
        this.isLoading = true
        const oldVersion = this.formData.version
        this.formData.version = this.dupeVersionName
        ModelingDataService.addScriptVersion(this.script.name, this.formData)
          .then(() => {
            Reloadables.requestReload()
            HelpContent.setTimedFlashMessage(`Duplicated version ${oldVersion} as ${this.dupeVersionName}`)
            this.close()
          })
          .catch(e => {
            console.log(e)
            let message = `Failed to Create Duplicate Version: ${this.dupeVersionName}`
            if (e.response && e.response.data && e.response.data.message) {
              message += `: ${e.response.data.message}`
            }
            HelpContent.setFlashMessage(message, true)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    createVersion () {
      this.formData.secrets = this.secretsCSV
      this.isLoading = true
      ModelingDataService.addScriptVersion(this.script.name, this.formData)
        .then(() => {
          Reloadables.requestReload()
          HelpContent.setTimedFlashMessage('New Version was created')
          this.close()
        })
        .catch(e => {
          console.log(e)
          let message = `Failed to Create Version ${this.formData.version}`
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateVersion () {
      const updateForm = {}
      let hasUpdates = this.secretsCSV !== this.version.secrets
      if (hasUpdates) {
        updateForm.secrets = this.secretsCSV
      }
      if (this.formData.description) {
        updateForm.description = this.formData.description
        hasUpdates = true
      }
      if (!this.formData.published) {
        if (this.formData.scriptBase64) {
          updateForm.scriptBase64 = this.formData.scriptBase64
          hasUpdates = true
        }
        if (this.formData.containerTag) {
          updateForm.containerTag = this.formData.containerTag
          hasUpdates = true
        }
        if (this.formData.scriptName) {
          updateForm.scriptName = this.formData.scriptName
          hasUpdates = true
        }
        if (this.formData.scriptFileName) {
          updateForm.scriptFileName = this.formData.scriptFileName
          hasUpdates = true
        }
      }
      if (!hasUpdates) {
        HelpContent.setTimedFlashMessage('Nothing to update')
      } else {
        this.isLoading = true
        ModelingDataService.updateScriptVersion(this.script.name, this.formData.version, updateForm)
          .then(() => {
            Reloadables.requestReload()
            HelpContent.setTimedFlashMessage('Version was updated')
            this.close()
          })
          .catch(e => {
            let message = 'Failed to Update Version'
            if (e.response && e.response.data && e.response.data.message) {
              message += `: ${e.response.data.message}`
            }
            HelpContent.setFlashMessage(message, true)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    filesChanged (e) {
      if (!e.target.files || e.target.files.length === 0) {
        this.selectedFileName = ''
        delete this.formData.scriptFileName
        delete this.formData.scriptBase64
        return
      }
      const files = e.target.files
      for (const file of files) {
        const reader = new FileReader()
        if (file) {
          reader.addEventListener('load', () => {
            this.selectedFileName = file.name
            this.formData.scriptBase64 = btoa(reader.result)
            this.formData.scriptFileName = file.name
          }, false)
          if (file.name.toLowerCase().endsWith('.zip')) {
            reader.readAsBinaryString(file)
          } else {
            reader.readAsText(file)
          }
        }
        break // only care about the first file
      }
    },
    reset () {
      this.mode = ''
      this.selectedFileName = ''
      if (this.version) {
        this.formData = { ...this.version }
        this.parseSecrets()
      } else {
        this.isCreate = true
        this.formData = {}
      }
    },
    show () {
      // nextTick ensures that this.version is set before
      // calling reset, to populate it into formData
      this.$nextTick(() => {
        this.reset()
        this.isBlocking = true
        let path = this.$route.href
        let st = path.indexOf('/run/')
        if (st > 0) {
          path = path.substring(0, st)
        }
        st = path.indexOf('/version/')
        if (st > 0) {
          path = path.substring(0, st)
        }
        this.oldRoute = path
        if (this.version && this.version.version) {
          history.pushState(
            {},
            null,
            path + '/version/' + this.version.version
          )
        }
      })
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
      history.pushState(
        {},
        null,
        this.oldRoute
      )
    }
  }
}
</script>
