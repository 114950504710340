<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close" :sz="modalSz" :isScrollable="modalSz === 'sm'">
      <template v-slot:header>
        {{ headerText }}
      </template>

      <template v-slot:body>
        <form @submit.prevent="handleSubmitUpdate"
          style="margin-top:-8px; margin-bottom:-12px; overflow-x: hidden"
          autocomplete="off">

          <div class="d-flex flex-wrap">
            <div class="my-3">
              <TextInputRow label="Description"
                v-model="formData.description"
                labelClass="label-required"
                :required="true"
                placeholder="Short and Human-Readable"
                maxlength="72"
                dataType="description"
              />

              <TextInputRow label="Alert Title"
                v-model="formData.subject"
                labelClass="label-required"
                :required="true"
                placeholder="Alert Title in the App"
                maxlength="72"
              />

              <SelectRow label="Alert Audience" v-model="formData.audienceDomains" :required="true" class="pb-1">
                <template v-slot:options>
                    <option v-for="(value, key) in audiences" :value="value" :key="value">
                      {{ key }}
                    </option>
                </template>
              </SelectRow>

              <CheckboxInputRow label=""
                v-model="formData.isCritical"
                checkboxLabel="Flag Alerts as Critical"
                id="cbIsCritical"
                class="pb-2"
              />

              <!-- TO DO: make Radio Input Row -->
              <div id="v-model-enabled" class="row form-group">
                <label class="col-form-label form-left-header width-115 label-required">Monitoring</label>
                <div class="col-auto form-right-input pt-1">
                  <input type="radio" class="form-check-input me-2" value="1" v-model="formData.enabled" id="monitoring-enabled" />
                  <label for="monitoring-enabled" class="me-4">Enabled</label>
                  <input type="radio" class="form-check-input me-2" value="0" v-model="formData.enabled" id="monitoring-disabled" />
                  <label for="monitoring-disabled">Disabled</label>
                </div>
              </div>

              <SelectRow label="Category" v-model="formData.category" :required="true" class="pb-1">
                <template v-slot:options>
                    <NotificationCategories />
                </template>
              </SelectRow>

              <SelectRow label="Scope" v-model="formData.scope" :required="true" class="pb-1">
                <template v-slot:options>
                    <NotificationScopes />
                </template>
              </SelectRow>

              <TextInputRow label="Filter"
                v-model="formData.filter"
                placeholder="customer=acme;site=anvil;node=cliff"
                maxlength="72"
              />

              <hr />
              <div class="form-section-heading">Condition</div>

              <SelectRow label="Source" v-model="formData.source" :required="true" class="pb-1">
                <template v-slot:options>
                    <NotificationSources />
                </template>
              </SelectRow>

              <SelectRow label="Metric" v-model="formData.metric" :required="true" class="pb-1">
                <template v-slot:options>
                    <NotificationTomorrowIOTelemetry v-if="formData.source == 'tomorrow-io'"/>
                    <NotificationNasaTelemetry v-else-if="formData.source == 'nasa'"/>
                    <NotificationMetricsTelemetry v-else/>
                </template>
              </SelectRow>

              <SelectRow label="Operator" v-model="formData.operator" :required="true" class="pb-1">
                <template v-slot:options>
                    <ThresholdOperators />
                </template>
              </SelectRow>

              <TextInputRow label="Value"
                v-model="formData.startVal"
                placeholder="3.14159"
                maxlength="32"
                :required="true"
              />
              <p style="margin-left: 130px" v-if="formData.operator === 'btw'">to</p>
              <TextInputRow label=""
                v-model="formData.endVal"
                placeholder="3.14159"
                maxlength="32"
                :required="true"
                v-if="formData.operator === 'btw'"
              />

              <SelectRow label="Compare To" v-model="formData.compareTo" :required="true" class="pb-1">
                <template v-slot:options>
                    <ThresholdComparators />
                </template>
              </SelectRow>

            </div>
            <hr class="form-section d-md-none" />

            <div class="d-none d-md-block add-border-right ms-2 me-2 my-0 py-0"></div>

            <div class="my-3">
            <div class="form-section-heading ms-0 ps-1">Triggering</div>

            <div class="row form-group">
              <label class="col-form-label form-left-header width-115 ms-1" for="triggerTrendSecs">Minimum Trend</label>
              <div class="col-auto form-right-input">
                <input
                  type="text"
                  id="triggerTrendSecs"
                  v-model="formData.triggerTrendSecs"
                  name="triggerTrendSecs"
                  style="width:100px;"
                  placeholder="0"
                  maxlength="72"
                /> seconds
              </div>
            </div>

            <div class="row form-group">
              <div class="col-auto form-right-input">
                <div class="mb-2">Notifications Sent When Triggered:</div>
                <div v-for="(_, i) in notifTemplates" :key="i" class="d-flex mb-2">
                  <select class="form-select me-1" v-model="notifTemplates[i]" id="triggerTemplates" style="width: 295px">
                    <option value="">None</option>
                    <option v-for="tmpl in templates" :value="tmpl.id" :key="tmpl.id">
                      {{ tmpl.medium }} - {{ tmpl.description }}
                    </option>
                  </select>
                  <span class="ms-2" style="font-weight:bold">
                    <a class="normal" @click="remNotifTemplate(i)">&#x2715;</a>
                  </span>
                </div>
                <div class="mt-2">
                  <a class="normal" @click="newNotifTemplate()">Add a Notification</a>
                </div>
              </div>
            </div>

            <hr />

            <div class="form-section-heading ps-1">Resolution</div>

            <div class="row form-group">
              <label class="col-form-label form-left-header width-115 ms-1" for="triggerTrendSecs">Minimum Trend</label>
              <div class="col-auto form-right-input">
                <input
                  type="text"
                  id="resolveTrendSecs"
                  v-model="formData.resolveTrendSecs"
                  name="resolveTrendSecs"
                  style="width:100px;"
                  placeholder="0"
                  maxlength="72"
                /> seconds
              </div>
            </div>

            <hr />

            <!-- <div class="form-section-heading">Flapping</div>

            <div class="row form-group">
              <label class="col-form-label form-left-header width-115" for="triggerTrendSecs">Minimum Trend</label>
              <div class="col-auto form-right-input">
                <input
                  type="text"
                  id="flapBufferSecs"
                  v-model="formData.flapBufferSecs"
                  name="flapBufferSecs"
                  style="width:100px;"
                  placeholder="0"
                  maxlength="72"
                /> seconds
              </div>
            </div>

            <hr /> -->

            <div class="row form-group">
              <label class="col-form-label form-left-header width-55" for="notes">Notes</label>
              <div class="col-auto form-right-input">
                <textarea
                  id="notes"
                  v-model="formData.notes"
                  name="notes"
                  class="notes-box width-270"
                  placeholder="Enter any info about this Threshold that Agrology may find useful in the future."
                />
              </div>
            </div>
          </div>
        </div>
       </form>

      </template>

      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn" @click="handleSubmitUpdate" v-if="!isCreate"
            :disabled="isLoading || !formData">{{ updateButtonText }}</button>
          <button class="btn" v-else
            @click="handleSubmitCreate"
            :disabled="isLoading">Create Threshold</button>
          <button class="btn btn-blue" :disabled="isLoading" style="margin-left: 20px" @click.prevent="close()">Cancel</button>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import BaseThresholdComponent from '@/components/notifications/BaseThresholdComponent.vue'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import NotificationCategories from '@/components/selectOptions/NotificationCategories.vue'
import NotificationSources from '@/components/selectOptions/NotificationSources.vue'
import NotificationMetricsTelemetry from '@/components/selectOptions/NotificationMetricsTelemetry.vue'
import NotificationTomorrowIOTelemetry from '@/components/selectOptions/NotificationTomorrowIOTelemetry.vue'
import NotificationNasaTelemetry from '@/components/selectOptions/NotificationNasaTelemetry.vue'
import NotificationScopes from '@/components/selectOptions/NotificationScopes.vue'
import ThresholdOperators from '@/components/selectOptions/ThresholdOperators.vue'
import ThresholdComparators from '@/components/selectOptions/ThresholdComparators.vue'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import CheckboxInputRow from '@/components/forms/CheckboxInputRow.vue'

import Modal from '@/components/Modal.vue'

export default {
  name: 'threshold-add-update',
  extends: BaseThresholdComponent,
  props: ['thresh'],
  data () {
    return {
      notifTemplates: [],
      audiences: {
        'Customers & Staff': '',
        'Staff Only': 'agrology.ag,virga.io'
      },
      formData: {},
      isCreate: false,
      modalSz: 'sm',
      isBlocking: false
    }
  },
  computed: {
    updateButtonText () {
      if (!this.formData) {
        return 'Create Threshold'
      }
      return 'Update Threshold'
    },
    headerText () {
      if (this.thresh) {
        return 'Edit Threshold'
      }
      return 'Add New Threshold'
    },
    parsedTemplates () {
      if (!this.formData || !this.formData.triggerTemplates || !this.templates) {
        return []
      }
      var mapped = []
      for (const n of this.notifTemplates) {
        for (const t of this.templates) {
          if (t.id === n) {
            mapped.push(t)
          }
        }
      }
      return mapped
    }
  },
  methods: {
    show () {
      this.reset()
      this.checkSize()
      this.isBlocking = true
      this.$nextTick(() => {
        this.timedCheckSize()
      })
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    reset () {
      if (this.thresh) {
        this.formData = { ...this.thresh }
        this.updateNotifTemplates()
      } else {
        this.isCreate = true
        this.formData = {}
      }
    },
    checkSize () {
      if (window.innerWidth < 760) {
        this.modalSz = 'sm'
      } else {
        this.modalSz = 'smx2'
      }
    },
    timedCheckSize () {
      if (!this.isBlocking) {
        return
      }
      this.checkSize()
      setTimeout(this.timedCheckSize, 150)
    },
    newNotifTemplate () {
      this.notifTemplates.push('')
    },
    remNotifTemplate (idx) {
      this.notifTemplates.splice(idx, 1)
    },
    getAudienceFromDomains (domains) {
      if (!domains) {
        return 'Customers & Staff'
      }
      for (const key in this.audiences) {
        if (this.audiences[key] === domains) {
          return key
        }
      }
      return domains
    },
    updateNotifTemplates () {
      if (!this.formData || !this.formData.triggerTemplates) {
        this.notifTemplates = []
        return
      }
      this.notifTemplates = this.formData.triggerTemplates.replaceAll(' ', '').split(',')
    },
    handleSubmitUpdate () {
      this.isLoading = true
      var out = []
      for (const i in this.notifTemplates) {
        if (this.notifTemplates[i]) {
          out.push(this.notifTemplates[i])
        }
      }
      if (this.formData.audienceDomains === '') {
        delete this.formData.audienceDomains
      }
      this.formData.triggerTemplates = out.join(',')
      NotificationsDataService.updateThreshold(this.formData.id, this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Threshold Updated')
          this.setNavThreshold(this.formData.description)
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Threshold Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleSubmitCreate () {
      var data = {
        description: this.formData.description,
        notes: this.formData.notes,
        enabled: this.formData.enabled,
        category: this.formData.category,
        isCritical: this.formData.isCritical,
        scope: this.formData.scope,
        filter: this.formData.filter,
        source: this.formData.source,
        metric: this.formData.metric,
        operator: this.formData.operator,
        compareTo: this.formData.compareTo,
        startVal: this.formData.startVal,
        endVal: this.formData.endVal,
        triggerTrendSecs: this.formData.triggerTrendSecs,
        triggerTemplates: this.formData.triggerTemplates,
        resolveTrendSecs: this.formData.resolveTrendSecs,
        resolveTemplates: this.formData.resolveTemplates,
        audienceDomains: this.formData.audienceDomains
      }
      var out = []
      for (const i in this.notifTemplates) {
        if (this.notifTemplates[i]) {
          out.push(this.notifTemplates[i])
        }
      }
      data.triggerTemplates = out.join(',')
      this.isLoading = true
      NotificationsDataService.createThreshold(data)
        .then((resp) => {
          HelpContent.setTimedFlashMessage('Threshold Created Successfully')
          this.$router.push({ name: 'threshold-details', params: { id: resp.data.id } })
        })
        .catch(e => {
          let message = 'Threshold Creation Failed'
          if (e.response && e.response.data && e.response.data.message) {
            if (e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
              message += ': Threshold Already Exists'
            } else {
              message += `: ${e.response.data.message}`
            }
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  mounted () {
    this.retrieveAllTemplates()
  },
  components: {
    NotificationCategories,
    NotificationSources,
    NotificationMetricsTelemetry,
    NotificationScopes,
    ThresholdOperators,
    ThresholdComparators,
    NotificationTomorrowIOTelemetry,
    NotificationNasaTelemetry,
    Modal,
    TextInputRow,
    SelectRow,
    CheckboxInputRow
  }
}
</script>

<style>
  .btn-blue {
    margin-left: 20px;
  }
</style>
