<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm">
      <template v-slot:header>
        {{ headerText }}
      </template>

      <template v-slot:body>
        <div class="ms-2 me-3">
          <TextInputRow label="Script Name"
            v-model="formData.name"
            :readonly="!isCreate"
            :inputClass="{'subtle-info': !isCreate}"
            :required="isCreate"
            dataType="version"
            maxlength="28"
          />
          <SelectRow label="Script Status" v-model="formData.status" :required="true" v-if="!isCreate">
            <template v-slot:options>
              <GeneralStatusOptions />
            </template>
          </SelectRow>
          <SelectRow label="Paused" v-model="formData.pauseUntil" v-if="!isCreate && formData.status === 'paused'" :required="true">
            <template v-slot:options>
              <option :value="formData.pauseUntil" v-if="formData.pauseUntil">until {{ getFormattedDateMinUTC(formData.pauseUntil) }}</option>
              <PauseUntilOptions />
            </template>
          </SelectRow>
          <TextInputRow label="Description" v-model="formData.description" dataType="description" />
          <SelectRow label="Active Version" v-model="formData.activeVersion" v-if="!isCreate">
            <template v-slot:options>
              <option value="">-- Select a Version --</option>
              <option v-for="(version, i) in publishedVersions" :key="i" :value="version.version">{{ version.version }}</option>
            </template>
          </SelectRow>
        </div>
      </template>

      <template v-slot:footer>
        <button class="btn" v-if="!script" @click="createScript()" :disabled="isLoading || !formData.name">
          Create Script
        </button>
        <button class="btn" v-if="script" @click="updateScript()" :disabled="isLoading">
          Update Script
        </button>
        <button class="btn btn-blue ms-2" @click="close()">
          Cancel
        </button>
      </template>
    </Modal>
  </transition>
</template>

<script>
import Modal from '@/components/Modal.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'
import PauseUntilOptions from '@/components/selectOptions/PauseUntilOptions.vue'
import Reloadables from '@/services/reloadables'
import ModelingDataService from '@/services/ModelingDataService'
import BaseComponent from '@/components/BaseComponent.vue'
import HelpContent from '@/services/HelpContent'

export default {
  extends: BaseComponent,
  data () {
    return {
      isLoading: false,
      isBlocking: false,
      formData: {},
      isCreate: false,
      mode: '',
      publishedVersions: []
    }
  },
  props: ['script'],
  components: { Modal, TextInputRow, SelectRow, GeneralStatusOptions, PauseUntilOptions },
  watch: {
    script: {
      handler () {
        if (this.script) {
          this.getPublishedScriptVersions()
        }
      }
    }
  },
  computed: {
    pauseUntilValue () {
      if (!this.formData.pauseUntil) {
        return ''
      }
      if (this.formData.pauseUntil === '1h') {
        return Date.now() + 3600000 + 300000
      }
      if (this.formData.pauseUntil === '4h') {
        return Date.now() + (4 * 3600000) + 300000
      }
      if (this.formData.pauseUntil === '12h') {
        return Date.now() + (12 * 3600000) + 300000
      }
      if (this.formData.pauseUntil === '1d') {
        return Date.now() + (1 * 86400000) + 300000
      }
      if (this.formData.pauseUntil === '3d') {
        return Date.now() + (3 * 86400000) + 300000
      }
      if (this.formData.pauseUntil === '1w') {
        return Date.now() + (7 * 86400000) + 300000
      }
      if (this.formData.pauseUntil === '2w') {
        return Date.now() + (14 * 86400000) + 300000
      }
      if (this.formData.pauseUntil === '1m') {
        return Date.now() + (30 * 86400000) + 300000
      }
      if (this.formData.pauseUntil === '3m') {
        return Date.now() + (91 * 86400000) + 300000
      }
      if (this.formData.pauseUntil === '4m') {
        return Date.now() + (122 * 86400000) + 300000
      }
      if (this.formData.pauseUntil === '6m') {
        return Date.now() + (184 * 86400000) + 300000
      }
      if (this.formData.pauseUntil === '1y') {
        return Date.now() + (365 * 86400000) + 300000
      }
      return this.formData.pauseUntil
    },
    headerText () {
      if (this.script) {
        return 'Edit Script'
      }
      return 'Add New Script'
    }
  },
  methods: {
    createScript () {
      this.isLoading = true
      ModelingDataService.addScript(this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Script Created Succesfully')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Script Creation Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateScript () {
      this.isLoading = true
      const name = this.formData.name
      delete this.formData.name
      if (this.formData.status === 'paused') {
        this.formData.pauseUntil = this.pauseUntilValue
      } else {
        delete this.formData.pauseUntil
      }
      ModelingDataService.updateScript(name, this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Script Updated Succesfully')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Script Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getPublishedScriptVersions () {
      this.publishedVersions = []
      if (!this.script) {
        return
      }
      this.isLoading = true
      ModelingDataService.listScriptVersions(this.script.name)
        .then(response => {
          for (const i in response.data) {
            if (response.data[i].published) {
              this.publishedVersions.push(response.data[i])
            }
          }
        })
        .catch(e => {
          let message = 'Unable to load Published Script Versions'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    reset () {
      this.getPublishedScriptVersions()
      if (this.script) {
        this.formData = { ...this.script }
      } else {
        this.isCreate = true
        this.formData = {}
      }
    },
    show () {
      this.reset()
      this.isBlocking = true
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    }
  }
}
</script>
