<template>
    <transition name="modal">
      <Modal ref="baseModal" v-if="isBlocking && (email)" @close="close()" sz="sm">
        <template v-slot:header>
          Confirm User Deletion
        </template>
        <template v-slot:body>
          <div class="m-4">
            Pressing <span class="warning">Yes, Delete User</span> will remove <b>all</b> App and API access for:
            <br /><br />
            <span class="font-enlarge-1 bold">{{ email }}</span>
            <br /><br />
            This includes customer-wide access, site-specific access, and the user's API Key, if one exists.
            <br /><br />
            It may take up to 5 minutes for the user to be fully removed.
            <br /><br />
            <span class="warning">
              <fa icon="exclamation-triangle" class="flash-icon-svg" />
              This action cannot be undone.
            </span>
          </div>
        </template>
        <template v-slot:footer>
          <div class="col-auto buttons-right">
            <button class="btn btn-red" @click="deleteUser" :disabled="isLoading">Yes, Delete User</button>
            <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="close()">Cancel</a>
          </div>
        </template>
      </Modal>
    </transition>
  </template>
<script>
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import AdminDataService from '@/services/admin/AdminDataService'

import Modal from '@/components/Modal.vue'

export default {
  name: 'delete-app-user',
  props: ['email', 'reloader'],
  data () {
    return {
      isBlocking: false,
      isLoading: false
    }
  },
  methods: {
    show () {
      this.isBlocking = true
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    deleteUser () {
      this.isLoading = true
      AdminDataService.deleteAppUser(this.email)
        .then(() => {
          HelpContent.setTimedFlashMessage(`User [${this.email}] has been deleted from the system`)
          if (this.reloader) {
            this.reloader()
          }
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = `Failed to delete User [${this.email}] from the system`
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  components: { Modal }
}
</script>
