<template>
  <div class="modal-mask">
    <div :class="wrapperClass">
      <div :class="sizeClass">
        <div class="modal-header align-bottom">
          <slot name="logo" />
          <h4 class="me-auto align-bottom mb-2"><slot name="header" /></h4>
          <Spinner ref="modalSpinner" class="mb-1 me-3" v-if="reloadable" @click="requestReload()"/>
        </div>

        <div :class="{'scrollable-modal': isScrollable}">
          <div class="modal-body mt-2 mb-2" :class="bodyClass">
            <slot name="body" />
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <span class="subtle-info" v-if="closeTime">{{ closeTime }}</span>
              <button class="btn btn-blue" @click="$emit('close')">
                Close
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from '@/components/Spinner.vue'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

export default {
  components: { Spinner },
  props: ['sz', 'reloadable', 'isScrollable', 'bodyClass'],
  data () {
    return {
      ct: -1,
      closeTime: '',
      userClosed: false,
      timedMessage: '',
      width: window.innerWidth
    }
  },
  computed: {
    displayedMessage () {
      if (this.timedMessage) {
        return this.timedMessage
      }
      if (this.message) {
        return this.message
      }
      return ''
    },
    wrapperClass () {
      if (this.sz === 'fs') {
        return 'modal-wrapper-fs'
      }
      return 'modal-wrapper'
    },
    sizeClass () {
      var size = 'modal-md'
      if (this.sz === 'sm') {
        size = 'modal-sm'
      } else if (this.sz === 'lg') {
        size = 'modal-lg'
      } else if (this.sz === 'smx2') {
        size = 'modal-smx2'
      } else if (this.sz === 'xl') {
        size = 'modal-xl'
      } else if (this.sz === 'fs') {
        size = 'modal-fs'
      } else if (this.sz === 'auto') {
        if (this.width < 760) {
          size = 'modal-sm'
        } else if (this.width < 900) {
          size = 'modal-md'
        } else {
          size = 'modal-lg'
        }
      }
      return `modal-container ${size}`
    }
  },
  methods: {
    checkWidth () {
      if (this.userClosed || this.sz !== 'auto') {
        return
      }
      this.width = window.innerWidth
      setTimeout(this.checkWidth, 100)
    },
    checkSpin () {
      if (this.reloadable &&
        this.$refs.modalSpinner && this.$refs.modalSpinner.stopSpinning) {
        if (Reloadables.isReloading) {
          setTimeout(this.checkSpin, 50)
          return
        }
        this.$refs.modalSpinner.stopSpinning()
      }
    },
    requestReload () {
      if (this.reloadable &&
        this.$refs.modalSpinner && this.$refs.modalSpinner.startSpinning) {
        this.$refs.modalSpinner.startSpinning()
        setTimeout(this.checkSpin, 50)
      }
      Reloadables.requestReload()
    },
    startTimedMessage (message, timer = 3000) {
      this.timedMessage = message
    },
    remainingCloseTime () {
      return this.closeTime
    },
    startTimedClosed (secs) {
      this.ct = secs
      this.closeTime = secs.toString()
      if (this.ct < 0) {
        return
      }
      this.timedClose()
    },
    timedClose () {
      if (this.userClosed) {
        return
      }
      if (this.ct >= 1) {
        this.closeTime = this.ct.toString()
        this.ct -= 1
        this.$emit('updateCloseTime')
        setTimeout(this.timedClose, 1000)
        return
      }
      if (this) {
        this.closeTime = ''
        this.$emit('close')
      }
    },
    handleKeyPress (e) {
      if (e.code === 'Escape') {
        this.$emit('close')
      }
    },
    close (message = '', isError = false, requestReload = false) {
      if (message !== '') {
        HelpContent.setTimedFlashMessage(message)
      }
      if (requestReload) {
        this.requestReload()
      }
      this.userClosed = true
      document.removeEventListener('keydown', this.handleKeyPress)
    }
  },
  mounted () {
    document.addEventListener('keydown', this.handleKeyPress)
    this.checkWidth()
  },
  unmounted () {
    document.removeEventListener('keydown', this.handleKeyPress)
    this.userClosed = true
  }
}
</script>
