<template>
  <mapbox-map
    ref="mainMapControl"
    :mapStyle="mapBoxStyleURL"
    :accessToken="accessToken"
    :height="mapHeight"
    width="100%"
    :center="mapCenter"
    :auto-resize="true"
    :bounds="mapBounds"
    v-if="(center || (markers && markers.length > 0) || (polygons && polygons.length > 0)) && !isLoading"
    >
    <mapbox-geolocate-control
      :showUserHeading="true"
      :trackUserLocation="true"
      :showAccuracyCircle="true"
      :showUserLocation="true"
      :positionOptions="{ enableHighAccuracy: true }"
    />
    <mapbox-marker v-if="pinIcon && !hideMainMarker" :lngLat="center" :scale="0.65">
      <template v-slot:icon>
        <fa :icon="pinIcon" class="map-icon map-icon-main" />
      </template>
    </mapbox-marker>
    <mapbox-marker v-for="marker, i in markers" :lngLat="marker.coords" :key="i" :scale="0.65">
      <template v-slot:icon>
        <fa icon="map-pin" class="map-icon" :style="`color:${nodeColors[i]}`" />
      </template>
      <mapbox-popup v-if="marker.title" :offset="[0,-22]" maxWidth="300px" className="map-popup">
        <div style="color: #666">
          <span v-if="marker.title" style="font-weight: bold;">
            {{marker.title}}
          </span>
        </div>
      </mapbox-popup>
    </mapbox-marker>
    <mapbox-marker v-for="marker, i in gateways" :lngLat="marker.coords" :key="i" :scale="0.65">
      <template v-slot:icon>
        <fa icon="tower-broadcast" class="map-icon status-normal-map" />
      </template>
      <mapbox-popup v-if="marker.description" :offset="[0,-22]" maxWidth="300px" className="map-popup">
        <div style="color: #666">
          <span v-if="marker.description" style="font-weight: bold;">
            GW {{marker.description}}
          </span>
        </div>
      </mapbox-popup>
    </mapbox-marker>
    <mapbox-geogeometry-polygon v-for="path, i in polygons" :key="i" :path="path">
      <mapbox-geogeometry-fill color="#22384a" :opacity="0.3" />
    </mapbox-geogeometry-polygon>
  </mapbox-map>
</template>
<script>
import { MapboxMap, MapboxMarker, MapboxGeogeometryPolygon, MapboxGeogeometryFill, MapboxGeolocateControl, MapboxPopup } from 'vue-mapbox-ts'

export default {
  name: 'site-map-control',
  props: ['center', 'markers', 'gateways', 'polygons', 'hideMainMarker', 'nodeColors', 'pinIcon', 'hideStyles', 'mapHeight', 'mapBoxStyle'],
  data () {
    return {
      isLoading: false
    }
  },
  components: {
    MapboxMap,
    MapboxMarker,
    MapboxGeogeometryPolygon,
    MapboxGeogeometryFill,
    MapboxGeolocateControl,
    MapboxPopup
  },
  methods: {
    stopLoading () {
      this.isLoading = false
    },
    calcPolygonArea (vertices) {
      var total = 0
      for (var i = 0, l = vertices.length; i < l; i++) {
        var addX = vertices[i][1]
        var addY = vertices[i === vertices.length - 1 ? 0 : i + 1][0]
        var subX = vertices[i === vertices.length - 1 ? 0 : i + 1][1]
        var subY = vertices[i][0]
        total += (addX * addY * 0.5)
        total -= (subX * subY * 0.5)
      }
      return Math.abs(total)
    },
    calcPolygonCenter (arr) {
      var minX, maxX, minY, maxY
      for (var i = 0; i < arr.length; i++) {
        minX = (arr[i][0] < minX || minX == null) ? arr[i][0] : minX
        maxX = (arr[i][0] > maxX || maxX == null) ? arr[i][0] : maxX
        minY = (arr[i][1] < minY || minY == null) ? arr[i][1] : minY
        maxY = (arr[i][1] > maxY || maxY == null) ? arr[i][1] : maxY
      }
      return [(minX + maxX) / 2, (minY + maxY) / 2]
    }
  },
  computed: {
    mapBounds () {
      let minLat = 10000
      let minLong = 10000
      let maxLat = -10000
      let maxLong = -10000
      if (this.polygons) {
        for (const polygon of this.polygons) {
          for (const point of polygon) {
            if (point[0] < minLong) {
              minLong = point[0]
            }
            if (point[0] > maxLong) {
              maxLong = point[0]
            }
            if (point[1] < minLat) {
              minLat = point[1]
            }
            if (point[1] > maxLat) {
              maxLat = point[1]
            }
          }
        }
      }
      if (this.gateways) {
        for (const gw of this.gateways) {
          if (gw.coords && gw.coords.length === 2) {
            if (gw.coords[0] < minLong) {
              minLong = gw.coords[0]
            }
            if (gw.coords[0] > maxLong) {
              maxLong = gw.coords[0]
            }
            if (gw.coords[1] < minLat) {
              minLat = gw.coords[1]
            }
            if (gw.coords[1] > maxLat) {
              maxLat = gw.coords[1]
            }
          }
        }
      }
      if (this.markers) {
        for (const mk of this.markers) {
          if (mk.coords && mk.coords.length === 2) {
            if (mk.coords[0] < minLong) {
              minLong = mk.coords[0]
            }
            if (mk.coords[0] > maxLong) {
              maxLong = mk.coords[0]
            }
            if (mk.coords[1] < minLat) {
              minLat = mk.coords[1]
            }
            if (mk.coords[1] > maxLat) {
              maxLat = mk.coords[1]
            }
          }
        }
      }
      if (this.center && this.center.length === 2) {
        if (this.center[0] < minLong) {
          minLong = this.center[0]
        }
        if (this.center[0] > maxLong) {
          maxLong = this.center[0]
        }
        if (this.center[1] < minLat) {
          minLat = this.center[1]
        }
        if (this.center[1] > maxLat) {
          maxLat = this.center[1]
        }
      }
      if (minLat === 10000 || minLong === 10000 ||
        maxLat === -10000 || maxLong === -10000) {
        return null
      }
      return [minLong - 0.0002, minLat - 0.0002, maxLong + 0.0002, maxLat + 0.0002]
    },
    mapCenter () {
      if (this.center) {
        return this.center
      }
      if (this.polygons) {
        const arr = []
        for (const polygon of this.polygons) {
          for (const point of polygon) {
            if (point.length === 2) {
              arr.push(point)
            }
          }
        }
        return this.calcPolygonCenter(arr)
      }
      return [0, 0]
    },
    mapBoxStyleURL () {
      if (this.mapBoxStyle) {
        return this.mapBoxStyle
      }
      return 'satellite-v9'
    },
    accessToken () {
      return process.env.VUE_APP_MAP_LICENCE_KEY
    }
  }
}
</script>
