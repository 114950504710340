<template>
  <div v-if="majorError">
    <h5 class="app-failure">{{ majorError }}</h5>
  </div>
  <InfoPane
    class="site-info-pane"
    title="General Site Information"
    v-else-if="site"
    :actionFunc="showEditModal"
    actionIcon="pen-to-square"
    itemType="Site Info"
  >
    <template v-slot:info>
      <Modal ref="editModal" :siteData="site" />
      <ModalChangeLog ref="changeLogModal" elementType="site" :displayName="site.displayName" :elementID="site.id" />
      <ul v-if="site" class="info-pane-list">

        <li class="info-pane-list-item" v-if="site.status === 'inactive'">
          <span class="node-inactive-header">
            INACTIVE
          </span>
        </li>
        <li class="info-pane-list-item">
          <ElementIdRow label="ID" :value="site.id" />
        </li>
        <li class="info-pane-list-item">
          <span v-if="site.displayName">
            <span class="list-heading-label">
              Display Name:
            </span>
            {{ site.displayName }}
          </span>
          <span v-else class="label-need-info">
            <fa icon="exclamation" class="map-icon status-danger" />
            Display Name Field is Empty
          </span>
        </li>
        <li class="info-pane-list-item" v-if="site.siteClassification"><label class="info-item-label">Classification:</label><span class="capitalize">{{ site.siteClassification }}</span></li>
        <li class="info-pane-list-item" v-if="site.products && site.products.length > 0"><label class="info-item-label">Product:</label><span class="capitalize">{{ site.products[0] }}</span></li>
        <li class="info-pane-list-item m-0 p-0">
          <div class="d-flex">
            <div class="p-2">
              <span class="list-heading-label me-2">Monitoring:</span>

              <label class="simple-badge" title="Environmental Monitoring">
                <fa icon="cloud-sun-rain" class="flash-icon-svg" />
                <span class="add-border-right mx-2"></span>
                <span v-if="site.envMonitoringStatus" class="notable">
                  <fa icon="check" class="flash-icon-svg" style="height:18px" />
                </span>
                <span v-else>
                  <fa icon="xmark" class="map-icon status-warning" style="height:18px" />
                </span>
              </label>

              <label class="simple-badge" title="Equipment Monitoring" >
                <fa icon="heart-pulse" class="flash-icon-svg" />
                <span class="add-border-right mx-2"></span>
                <span v-if="site.hwMonitoringStatus" class="notable">
                  <fa icon="check" class="flash-icon-svg" style="height:18px" />
                </span>
                <span v-else>
                  <fa icon="xmark" class="map-icon status-warning" style="height:18px" />
                </span>
              </label>

              <label class="simple-badge" title="Visible in Grafana">
                <fa icon="chart-area" class="flash-icon-svg" />
                <span class="add-border-right mx-2"></span>
                <span v-if="site.grafanaEnabled" class="notable">
                  <fa icon="check" class="flash-icon-svg" style="height:18px" />
                </span>
                <span v-else class="me-1">
                  --
                </span>
              </label>

            </div>
          </div>
        </li>
        <li class="info-pane-list-item" v-if="site.notes">
          <span class="node-notes node-site-notes">
            <pre class="notes">{{ site.notes }}</pre>
          </span>
        </li>
        <li class="info-pane-list-item" v-if="customer && customer.notes">
          <span class="node-notes node-customer-notes">
            <pre class="notes">{{ customer.notes }}</pre>
          </span>
        </li>
        <li class="info-pane-list-item" v-if="(site && site.customer && customer)">
            <span class="attribute-label attribute-label-left">Customer:</span>
            <router-link class="padded" :class="{'inactive': customer && customer.status === 'inactive'}"
              :to="{  name: 'customer-details', params: { id: site.customer } }">
              {{ customer.displayName }}
            </router-link>
            <span class="inactive" v-if="customer && customer.status === 'inactive'"> (inactive)</span>
        </li>
        <li class="info-pane-list-item" v-if="site.contactName || site.email || site.phone">
          <div class="contact-row-item" v-if="site.contactName">
            <span class="attribute-label capitalize">{{ site.siteClassification }} Contact:</span> {{ site.contactName }}
          </div>
          <div class="contact-row-item px-1 mb-2" v-if="site.email">
            <a class="padded" v-bind:href="'mailto:' + site.email">
              <fa icon="envelope" class="button-icon me-2" />
              {{ site.email }}
            </a>
          </div>
          <div class="contact-row-item px-1" v-if="site.phone">
            <a class="padded" v-bind:href="'tel:' + site.phone">
              <fa icon="square-phone-flip" class="button-icon me-2" />
              {{ site.phone }}
            </a>
          </div>
        </li>
        <li class="info-pane-list-item d-flex align-items-center">
          <div class="contact-row-item px-1">
            <router-link class="padded" :to="{ name: 'admin-app-user-access-customer', params: {customer: site.customer}}">
              <fa icon="user-shield" class="button-icon me-2" />
              Manage App/API Access for this Site
            </router-link>
          </div>
        </li>
        <li class="info-pane-list-item" v-if="site.lastModified">
          <fa icon="clock-rotate-left" class="button-icon ps-1 me-2" title="Change Log" alt="Change Log" @click="showChangeLogModal"/>
          <span class="subtle-info status-last-modified">
            {{ getSinceSecs(site.lastModified) }}
          </span>
          <span class="subtle-info font-reduce-2" v-if="site.lastModifiedBy"> by {{ site.lastModifiedBy }}</span>
        </li>
      </ul>
    </template>
  </InfoPane>
</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'
import InfoPane from '@/components/tables/InfoPane.vue'
import Modal from '@/components/fleet/ModalAddUpdateSite.vue'
import ModalChangeLog from '@/components/ModalChangeLog.vue'
import ElementIdRow from '@/components/forms/ElementIdRow.vue'

export default {
  extends: BaseComponent,
  components: { InfoPane, Modal, ElementIdRow, ModalChangeLog },
  props: ['site', 'customer'],
  data () {
    return {
    }
  },
  methods: {
    showEditModal () {
      this.$refs.editModal.show()
    },
    showChangeLogModal () {
      this.$refs.changeLogModal.show()
    }
  }
}
</script>
