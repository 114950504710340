import { DecodeOyster } from './digitalmatter'
import { Decode as DecodeSeeed } from './seeed'
import { MeasurementLookup } from './types'

class DecoderService {
  decode (format: string, data: string): MeasurementLookup {
    if (!data) {
      return {} as MeasurementLookup
    }
    switch (format) {
      case 'v1_ground': {
        return this.decodeAgrologyV1Ground(data)
      }
      case 'v2': {
        return this.decodeAgrologyV2(data)
      }
      case 'v0_ghg': {
        return this.decodeAgrologyV0GHG(data)
      }
      case 'v0_5ghg': {
        return this.decodeAgrologyV0_5GHG(data)
      }
      case 'pm': {
        return this.decodeDecentLabPM(data)
      }
      case 'trs12': {
        return this.decodeDecentLabTRS12(data)
      }
      case 'trs21': {
        return this.decodeDecentLabTRS21(data)
      }
      case 'dws': {
        return this.decodeDecentLabDWS(data)
      }
      case 'lp8p': {
        return this.decodeDecentLabLP8P(data)
      }
      case 'smtp': {
        return this.decodeDecentLabSMTP(data)
      }
      case 's2103': {
        return DecodeSeeed(data)
      }
      case 'oyster3': {
        return DecodeOyster(data)
      }
    }
    return {} as MeasurementLookup
  }

  twosCompliment16 (input: number) {
    if (input >= 32768) {
      input = -(~(input + 4294901760) + 1)
    }
    return input
  }

  decodeAgrologyV1Ground (data: string): MeasurementLookup {
    const out = {} as MeasurementLookup
    
    if (data.length !== 46) {
      return out
    }

    data = data.toUpperCase()

    out['Battery '] = {
      Value: (Math.round((parseInt(data.substring(18, 22), 16) * 1000) / 4096) * 5) / 1000,
      Units: 'V'
    }

    out['Air Temp'] = {
      Value: this.twosCompliment16(parseInt(data.substring(6, 10), 16)) / 100,
      Units: '˚C'
    }

    out['Air Humidity'] = {
      Value: parseInt(data.substring(10, 12), 16) / 2,
      Units: '%'
    }

    out['Barometric Pressure'] = {
      Value: parseInt(data.substring(2, 6), 16) / 10,
      Units: 'hPa'
    }

    out['VOC Sensor Ready'] = {
      Value: parseInt(data.substring(12, 14), 16) === 1
    }

    out['Total VOCs'] = {
      Value: parseInt(data.substring(14, 18), 16) / 1000,
      Units: 'mg/m³'
    }

    out['Soil Moisture Raw'] = {
      Value: parseInt(data.substring(22, 26), 16)
    }

    out['Soil Tension'] = {
      Value: parseInt(data.substring(26, 30), 16)
    }

    out['Soil Conductivity'] = {
      Value: parseInt(data.substring(30, 34), 16)
    }

    out['Soil Temp'] = {
      Value: this.twosCompliment16(parseInt(data.substring(34, 38), 16)) / 100,
      Units: '˚C'
    }

    out['Irrigation Count'] = {
      Value: parseInt(data.substring(38, 40), 16)
    }

    out['Irrigation Duration'] = {
      Value: parseInt(data.substring(40, 46), 16),
      Units: 's'
    }

    if (out['Soil Tension'].Value === 0) {
      delete out['Soil Tension']
    }

    if (out['Soil Conductivity'].Value === 0 && out['Soil Temp'].Value === 0) {
      delete out['Soil Temp']
    }

    if (out['Soil Conductivity'].Value === 0) {
      delete out['Soil Conductivity']
    }

    return out
  }

  decodeAgrologyV2 (data: string): MeasurementLookup {
    const out = {} as MeasurementLookup

    if (!data || data.length !== 54) {
      return out
    }

    data = data.toUpperCase()

    out['Battery '] = {
      Value: (Math.round((parseInt(data.substring(4, 8), 16) * 1000) / 4096) * 5) / 1000,
      Units: 'V'
    }

    out['Air Temp'] = {
      Value: this.twosCompliment16(parseInt(data.substring(14, 18), 16)) / 100,
      Units: '˚C'
    }

    out['Air Humidity'] = {
      Value: parseInt(data.substring(18, 22), 16) / 10,
      Units: '%'
    }

    out['Barometric Pressure'] = {
      Value: parseInt(data.substring(8, 14), 16) / 100,
      Units: 'hPa'
    }

    out['VOC Sensor Ready'] = {
      Value: parseInt(data.substring(22, 24), 16) === 1
    }

    out['Total VOCs'] = {
      Value: parseInt(data.substring(24, 28), 16) / 1000,
      Units: 'mg/m³'
    }

    out['Soil Moisture Raw'] = {
      Value: parseInt(data.substring(28, 32), 16)
    }

    out['Soil Tension'] = {
      Value: parseInt(data.substring(32, 36), 16)
    }

    out['Soil Conductivity'] = {
      Value: parseInt(data.substring(36, 40), 16)
    }

    out['Soil Temp'] = {
      Value: this.twosCompliment16(parseInt(data.substring(40, 44), 16)) / 100,
      Units: '˚C'
    }

    out['Irrigation In Progress'] = {
      Value: parseInt(data.substring(44, 46), 16) === 1
    }

    out['Irrigation Count'] = {
      Value: parseInt(data.substring(46, 48), 16)
    }

    out['Irrigation Duration'] = {
      Value: parseInt(data.substring(48, 54), 16),
      Units: 's'
    }

    if (out['Soil Tension'].Value === 0) {
      delete out['Soil Tension']
    }

    if (out['Soil Conductivity'].Value === 0 && out['Soil Temp'].Value === 0) {
      delete out['Soil Temp']
    }

    if (out['Soil Conductivity'].Value === 0) {
      delete out['Soil Conductivity']
    }

    return out
  }

  decodeAgrologyV0GHG (data: string): MeasurementLookup {
    const out = {} as MeasurementLookup
    if ((data.length !== 46 && data.length !== 22 && data.length !== 104) ||
        data.substring(0, 2) !== '20') {
      return out
    }

    data = data.toUpperCase()

    out['Battery '] = {
      Value: (Math.round((parseInt(data.substring(4, 8), 16) * 1000) / 4096) * 5) / 1000,
      Units: 'V'
    }

    out['Air Temp'] = {
      Value: this.twosCompliment16(parseInt(data.substring(14, 18), 16)) / 100,
      Units: '˚C'
    }

    out['Air Humidity'] = {
      Value: parseInt(data.substring(18, 22), 16) / 10,
      Units: '%'
    }

    out['Barometric Pressure'] = {
      Value: parseInt(data.substring(8, 14), 16) / 100,
      Units: 'hPa'
    }

    if (data.length < 46) {
      return out
    }

    out['AQI Sensor Ready'] = {
      Value: parseInt(data.substring(22, 24), 16) === 1
    }

    // v1.0.2 firmware and below
    if (data.length === 46) {
      out['AQI '] = {
        Value: parseInt(data.substring(40, 46), 16) / 10000
      }
      out['RMox 10'] = {
        Value: parseInt(data.substring(24, 32), 16),
        Units: 'Ω'
      }
      out['RMox 12'] = {
        Value: parseInt(data.substring(32, 40), 16),
        Units: 'Ω'
      }
      return out
    }

    // v1.0.3 firmware and above
    if (data.length !== 104) {
      return out
    }

    out['RMox 7 (0m)'] = {
      Value: parseInt(data.substring(88, 96), 16),
      Units: 'Ω'
    }

    out['RMox 12 (0m)'] = {
      Value: parseInt(data.substring(96, 104), 16),
      Units: 'Ω'
    }

    out['RMox 7 (1m)'] = {
      Value: parseInt(data.substring(72, 80), 16),
      Units: 'Ω'
    }

    out['RMox 12 (1m)'] = {
      Value: parseInt(data.substring(80, 88), 16),
      Units: 'Ω'
    }

    out['RMox 7 (2m)'] = {
      Value: parseInt(data.substring(56, 64), 16),
      Units: 'Ω'
    }

    out['RMox 12 (2m)'] = {
      Value: parseInt(data.substring(64, 72), 16),
      Units: 'Ω'
    }

    out['RMox 7 (3m)'] = {
      Value: parseInt(data.substring(40, 48), 16),
      Units: 'Ω'
    }

    out['RMox 12 (3m)'] = {
      Value: parseInt(data.substring(48, 56), 16),
      Units: 'Ω'
    }

    out['RMox 7 (4m)'] = {
      Value: parseInt(data.substring(24, 32), 16),
      Units: 'Ω'
    }

    out['RMox 12 (4m)'] = {
      Value: parseInt(data.substring(32, 40), 16),
      Units: 'Ω'
    }

    return out
  }

  decodeAgrologyV0_5GHG(data: string): MeasurementLookup {
    const out = {} as MeasurementLookup
    if ((data.length !== 22 && data.length !== 104 && data.length !== 106) ||
      data.substring(0, 2) !== '20' && data.substring(0, 2) !== '21') {
      return out
    }

    data = data.toUpperCase()

    if (data.length === 22) {
      out['Battery '] = {
        Value: (Math.round((parseInt(data.substring(4, 8), 16) * 1000) / 4096) * 5) / 1000,
        Units: 'V'
      }

      out['Air Temp'] = {
        Value: this.twosCompliment16(parseInt(data.substring(14, 18), 16)) / 100,
        Units: '˚C'
      }

      out['Air Humidity'] = {
        Value: parseInt(data.substring(18, 22), 16) / 10,
        Units: '%'
      }

      out['Barometric Pressure'] = {
        Value: parseInt(data.substring(8, 14), 16) / 100,
        Units: 'hPa'
      }

      return out
    }

    if (data.length == 104) {
      out['Battery '] = {
        Value: (Math.round((parseInt(data.substring(4, 8), 16) * 1000) / 4096) * 5) / 1000,
        Units: 'V'
      }

      out['Air Temp'] = {
        Value: this.twosCompliment16(parseInt(data.substring(14, 18), 16)) / 100,
        Units: '˚C'
      }

      out['Air Humidity'] = {
        Value: parseInt(data.substring(18, 22), 16) / 10,
        Units: '%'
      }

      out['Barometric Pressure'] = {
        Value: parseInt(data.substring(8, 14), 16) / 100,
        Units: 'hPa'
      }
      out['RMox 7 (-4m)'] = {
        Value: parseInt(data.substring(24, 32), 16),
        Units: 'Ω'
      }

      out['RMox 12 (-4m)'] = {
        Value: parseInt(data.substring(64, 72), 16),
        Units: 'Ω'
      }

      out['RMox 7 (-3m)'] = {
        Value: parseInt(data.substring(32, 40), 16),
        Units: 'Ω'
      }

      out['RMox 12 (-3m)'] = {
        Value: parseInt(data.substring(72, 80), 16),
        Units: 'Ω'
      }

      out['RMox 7 (-2m)'] = {
        Value: parseInt(data.substring(40, 48), 16),
        Units: 'Ω'
      }

      out['RMox 12 (-2m)'] = {
        Value: parseInt(data.substring(80, 88), 16),
        Units: 'Ω'
      }

      out['RMox 7 (-1m)'] = {
        Value: parseInt(data.substring(48, 56), 16),
        Units: 'Ω'
      }

      out['RMox 12 (-1m)'] = {
        Value: parseInt(data.substring(88, 96), 16),
        Units: 'Ω'
      }

      out['RMox 7 (0m)'] = {
        Value: parseInt(data.substring(56, 64), 16),
        Units: 'Ω'
      }

      out['RMox 12 (0m)'] = {
        Value: parseInt(data.substring(96, 104), 16),
        Units: 'Ω'
      }

      return out
    }

    if (data.length === 106) {
      out['Battery '] = {
        Value: Math.round((parseInt(data.substring(4, 6), 16) / 255 * 5) * 1000) / 1000,
        Units: 'V'
      }

      out['Air Temp'] = {
        Value: this.twosCompliment16(parseInt(data.substring(10, 13), 16)) / 20,
        Units: '˚C'
      }

      out['Air Humidity'] = {
        Value: parseInt(data.substring(16, 20), 16) / 10,
        Units: '%'
      }

      out['Barometric Pressure'] = {
        Value: Math.round((parseInt(data.substring(6, 10), 16) / 65535 * 360 + 810) * 100) / 100,
        Units: 'hPa'
      }

      out['Soil Temperature'] = {
        Value: this.twosCompliment16(parseInt(data.substring(13, 16), 16)) / 20,
        Units: '˚C'
      }

      out['Soil Moisture'] = {
        Value: parseInt(data.substring(20, 23), 16)
      }

      out['Soil Conductivity'] = {
        Value: parseInt(data.substring(23, 26), 16)
      }

      out['RMox 7 (-4m)'] = {
        Value: parseInt(data.substring(26, 34), 16),
        Units: 'Ω'
      }

      out['RMox 12 (-4m)'] = {
        Value: parseInt(data.substring(66, 74), 16),
        Units: 'Ω'
      }

      out['RMox 7 (-3m)'] = {
        Value: parseInt(data.substring(34, 42), 16),
        Units: 'Ω'
      }

      out['RMox 12 (-3m)'] = {
        Value: parseInt(data.substring(74, 82), 16),
        Units: 'Ω'
      }

      out['RMox 7 (-2m)'] = {
        Value: parseInt(data.substring(42, 50), 16),
        Units: 'Ω'
      }

      out['RMox 12 (-2m)'] = {
        Value: parseInt(data.substring(82, 90), 16),
        Units: 'Ω'
      }

      out['RMox 7 (-1m)'] = {
        Value: parseInt(data.substring(50, 58), 16),
        Units: 'Ω'
      }

      out['RMox 12 (-1m)'] = {
        Value: parseInt(data.substring(90, 98), 16),
        Units: 'Ω'
      }

      out['RMox 7 (0m)'] = {
        Value: parseInt(data.substring(58, 66), 16),
        Units: 'Ω'
      }

      out['RMox 12 (0m)'] = {
        Value: parseInt(data.substring(98, 106), 16),
        Units: 'Ω'
      }

      return out
    }

    return out
  }

  decodeDecentLabPM (data: string): MeasurementLookup {
    return decentlabDecoder.decode(data, decentlabPmDecoder)
  }

  decodeDecentLabTRS12 (data: string): MeasurementLookup {
    return decentlabDecoder.decode(data, decentlabTrs12Decoder)
  }

  decodeDecentLabTRS21 (data: string): MeasurementLookup {
    return decentlabDecoder.decode(data, decentlabTrs21Decoder)
  }

  decodeDecentLabLP8P (data: string): MeasurementLookup {
    const out = decentlabDecoder.decode(data, decentlabLp8pDecoder)
    if ('Air temperature' in out && typeof(out['Air temperature'].Value) === 'number' ) {
      out['Air temperature'].Value = Math.floor(out['Air temperature'].Value * 100) / 100
    }
    if ('CO2 sensor temperature' in out && typeof(out['CO2 sensor temperature'].Value) === 'number' ) {
      console.log('YES')
      out['CO2 sensor temperature'].Value = Math.floor(out['CO2 sensor temperature'].Value * 100) / 100
    }
    if ('Air humidity' in out && typeof(out['Air humidity'].Value) === 'number' ) {
      out['Air humidity'].Value = Math.floor(out['Air humidity'].Value * 100) / 100
    }
    return out
  }

  decodeDecentLabDWS (data: string): MeasurementLookup {
    const x = data.indexOf('|')
    if (x > 1) {
      const values = data.substring(x + 1).split(';')
      for (const val of values) {
        if (val.startsWith('tare=')) {
          m = parseFloat(val.replaceAll('tare=', ''))
        }
        if (val.startsWith('s=')) {
          s = parseFloat(val.replaceAll('s=', ''))
        }
        if (val.startsWith('f02=')) {
          f02 = parseFloat(val.replaceAll('f02=', ''))
        }
      }
      data = data.substring(0, x)
    }

    const out = decentlabDecoder.decode(data, decentlabDwsDecoder)
    if ('Frequency ' in out && typeof(out['Frequency '].Value) === 'number' ) {
      out['Frequency '].Value = Math.floor(out['Frequency '].Value * 100) / 100
    }
    if ('Weight ' in out && typeof(out['Weight '].Value) === 'number' ) {
      out['Weight '].Value = Math.floor(out['Weight '].Value * 100) / 100
    }
    return out
  }

  decodeDecentLabSMTP (data: string): MeasurementLookup {
    return decentlabDecoder.decode(data, decentlabSmtpDecoder)
  }
}

const decentlabTrs12Decoder = [
  {
    length: 3,
    values: [
      {
        name: 'dielectric_permittivity',
        displayName: 'Dielectric Permittivity',
        convert: function (x) { return (Math.pow(0.000000002887 * Math.pow(x[0] / 10, 3) - 0.0000208 * Math.pow(x[0] / 10, 2) + 0.05276 * (x[0] / 10) - 43.39, 2)).toFixed(2) }
      },
      {
        name: 'volumetric_water_content',
        displayName: 'Volumetric Water Content',
        convert: function (x) { return (x[0] / 10 * 0.0003879 - 0.6956).toFixed(2) },
        unit: 'm³⋅m⁻³'
      },
      {
        name: 'soil_temperature',
        displayName: 'Soil Temperature',
        convert: function (x) { return ((x[1] - 32768) / 10).toFixed(2) },
        unit: '°C'
      },
      {
        name: 'electrical_conductivity',
        displayName: 'Electrical Conductivity',
        convert: function (x) { return x[2] },
        unit: 'µS⋅cm⁻¹'
      }
    ]
  },
  {
    length: 1,
    values: [
      {
        name: 'battery_voltage',
        displayName: 'Battery Voltage',
        convert: function (x) { return (x[0] / 1000).toFixed(3) },
        unit: 'V'
      }
    ]
  }
]

const decentlabPmDecoder = [
  {
    length: 1,
    values: [
      {
        name: 'battery_voltage',
        displayName: 'Battery Voltage',
        convert: function (x) { return (x[0] / 1000).toFixed(3) },
        unit: 'V'
      }
    ]
  },
  {
    length: 10,
    values: [
      {
        name: 'pm1_0_mass_concentration',
        displayName: 'PM1.0 Mass Concentration',
        convert: function (x) { return (x[0] / 10) },
        unit: 'µg⋅m⁻³'
      },
      {
        name: 'pm2_5_mass_concentration',
        displayName: 'PM2.5 Mass Concentration',
        convert: function (x) { return x[1] / 10 },
        unit: 'µg⋅m⁻³'
      },
      {
        name: 'pm4_mass_concentration',
        displayName: 'PM4 Mass Concentration',
        convert: function (x) { return x[2] / 10 },
        unit: 'µg⋅m⁻³'
      },
      {
        name: 'pm10_mass_concentration',
        displayName: 'PM10 Mass Concentration',
        convert: function (x) { return x[3] / 10 },
        unit: 'µg⋅m⁻³'
      },
      {
        name: 'typical_particle_size',
        displayName: 'Typical Particle Size',
        convert: function (x) { return x[4] },
        unit: 'nm'
      },
      {
        name: 'pm0_5_number_concentration',
        displayName: 'PM0.5 # Concentration',
        convert: function (x) { return x[5] / 10 },
        unit: '#/m³'
      },
      {
        name: 'pm1_0_number_concentration',
        displayName: 'PM1.0 # Concentration',
        convert: function (x) { return x[6] / 10 },
        unit: '#/m³'
      },
      {
        name: 'pm2_5_number_concentration',
        displayName: 'PM2.5 # Concentration',
        convert: function (x) { return x[7] / 10 },
        unit: '#/m³'
      },
      {
        name: 'pm4_number_concentration',
        displayName: 'PM4 # Concentration',
        convert: function (x) { return x[8] / 10 },
        unit: '#/m³'
      },
      {
        name: 'pm10_number_concentration',
        displayName: 'PM10 # Concentration',
        convert: function (x) { return x[9] / 10 },
        unit: '#/m³'
      }
    ]
  },
  {
    length: 2,
    values: [
      {
        name: 'air_temperature',
        displayName: 'Air Temperature',
        convert: function (x) { return (175.72 * x[0] / 65536 - 46.85).toFixed(2) },
        unit: '°C'
      },
      {
        name: 'air_humidity',
        displayName: 'Air Humidity',
        convert: function (x) { return (125 * x[1] / 65536 - 6).toFixed(2) },
        unit: '%'
      }
    ]
  },
  {
    length: 1,
    values: [
      {
        name: 'barometric_pressure',
        displayName: 'Barometric pressure',
        convert: function (x) { return x[0] * 2 / 100 },
        unit: 'hPa'
      }
    ]
  }
]

const decentlabTrs21Decoder = [
  {
    length: 2,
    values: [
      {
        name: 'water_potential',
        displayName: 'Water potential',
        convert: function (x) { return -(x[0] / 10) },
        unit: 'kPa'
      },
      {
        name: 'soil_temperature',
        displayName: 'Soil temperature',
        convert: function (x) { return (x[1] - 32768) / 10 },
        unit: '°C'
      }
    ]
  },
  {
    length: 1,
    values: [
      {
        name: 'battery_voltage',
        displayName: 'Battery voltage',
        convert: function (x) { return x[0] / 1000 },
        unit: 'V'
      }
    ]
  }
]

const decentlabLp8pDecoder = [
  {
    length: 2,
    values: [
      {
        name: 'air_temperature',
        displayName: 'Air temperature',
        convert: function (x) { return 175.72 * x[0] / 65536 - 46.85 },
        unit: '°C'
      },
      {
        name: 'air_humidity',
        displayName: 'Air humidity',
        convert: function (x) { return 125 * x[1] / 65536 - 6 },
        unit: '%'
      }
    ]
  },
  {
    length: 2,
    values: [
      {
        name: 'barometer_temperature',
        displayName: 'Barometer temperature',
        convert: function (x) { return (x[0] - 5000) / 100 },
        unit: '°C'
      },
      {
        name: 'barometric_pressure',
        displayName: 'Barometric pressure',
        convert: function (x) { return (x[1] * 2) / 100 },
        unit: 'hPa'
      }
    ]
  },
  {
    length: 8,
    values: [
      {
        name: 'co2_concentration',
        displayName: 'CO2 concentration',
        convert: function (x) { return x[0] - 32768 },
        unit: 'ppm'
      },
      {
        name: 'co2_concentration_lpf',
        displayName: 'CO2 concentration LPF',
        convert: function (x) { return x[1] - 32768 },
        unit: 'ppm'
      },
      {
        name: 'co2_sensor_temperature',
        displayName: 'CO2 sensor temperature',
        convert: function (x) { return (x[2] - 32768) / 100 },
        unit: '°C'
      },
      {
        name: 'capacitor_voltage_1',
        displayName: 'Capacitor voltage 1',
        convert: function (x) { return x[3] / 1000 },
        unit: 'V'
      },
      {
        name: 'capacitor_voltage_2',
        displayName: 'Capacitor voltage 2',
        convert: function (x) { return x[4] / 1000 },
        unit: 'V'
      },
      {
        name: 'co2_sensor_status',
        displayName: 'CO2 sensor status',
        convert: function (x) { return x[5] }
      },
      {
        name: 'raw_ir_reading',
        displayName: 'Raw IR reading',
        convert: function (x) { return x[6] }
      },
      {
        name: 'raw_ir_reading_lpf',
        displayName: 'Raw IR reading LPF',
        convert: function (x) { return x[7] }
      }
    ]
  },
  {
    length: 1,
    values: [
      {
        name: 'battery_voltage',
        displayName: 'Battery voltage',
        convert: function (x) { return x[0] / 1000 },
        unit: 'V'
      }
    ]
  }
]

let m = 0
let f02 = 0
let s = 0

const decentlabDwsDecoder = [
  {
    length: 3,
    values: [
      {
        name: 'frequency',
        displayName: 'Frequency ',
        convert: function (x) { return x[0] / x[1] * 32768 },
        unit: 'Hz'
      },
      {
        name: 'weight',
        displayName: 'Weight ',
        convert: function (x) {
          return (Math.pow(x[0] / x[1] * 32768, 2) - f02) * s + m
        },
        unit: 'g'
      }
    ]
  },
  {
    length: 1,
    values: [
      {
        name: 'battery_voltage',
        displayName: 'Battery voltage',
        convert: function (x) { return x[0] / 1000 },
        unit: 'V'
      }
    ]
  }
]

const decentlabSmtpDecoder = [
  {
    length: 16,
    values: [
      {
        name: 'soil_moisture_1',
        displayName: 'Soil Moisture Depth 1',
        convert: function (x) { return ((x[0] - 2500) / 500) },
        unit: ''
      },
      {
        name: 'soil_temperature_1',
        displayName: 'Soil Temperature Depth 1',
        convert: function (x) { return ((x[1] - 32768) / 100.0) },
        unit: '°C'
      },
      {
        name: 'soil_moisture_2',
        displayName: 'Soil Moisture Depth 2',
        convert: function (x) { return ((x[2] - 2500) / 500) },
        unit: ''
      },
      {
        name: 'soil_temperature_2',
        displayName: 'Soil Temperature Depth 2',
        convert: function (x) { return ((x[3] - 32768) / 100.0) },
        unit: '°C'
      },
      {
        name: 'soil_moisture_3',
        displayName: 'Soil Moisture Depth 3',
        convert: function (x) { return ((x[4] - 2500) / 500) },
        unit: ''
      },
      {
        name: 'soil_temperature_3',
        displayName: 'Soil Temperature Depth 3',
        convert: function (x) { return ((x[5] - 32768) / 100.0) },
        unit: '°C'
      },
      {
        name: 'soil_moisture_4',
        displayName: 'Soil Moisture Depth 4',
        convert: function (x) { return ((x[6] - 2500) / 500) },
        unit: ''
      },
      {
        name: 'soil_temperature_4',
        displayName: 'Soil Temperature Depth 4',
        convert: function (x) { return ((x[7] - 32768) / 100.0) },
        unit: '°C'
      },
      {
        name: 'soil_moisture_5',
        displayName: 'Soil Moisture Depth 5',
        convert: function (x) { return ((x[8] - 2500) / 500) },
        unit: ''
      },
      {
        name: 'soil_temperature_5',
        displayName: 'Soil Temperature Depth 5',
        convert: function (x) { return ((x[9] - 32768) / 100.0) },
        unit: '°C'
      },
      {
        name: 'soil_moisture_6',
        displayName: 'Soil Moisture Depth 6',
        convert: function (x) { return ((x[10] - 2500) / 500) },
        unit: ''
      },
      {
        name: 'soil_temperature_6',
        displayName: 'Soil Temperature Depth 6',
        convert: function (x) { return ((x[11] - 32768) / 100.0) },
        unit: '°C'
      },
      {
        name: 'soil_moisture_7',
        displayName: 'Soil Moisture Depth 7',
        convert: function (x) { return ((x[12] - 2500) / 500) },
        unit: ''
      },
      {
        name: 'soil_temperature_7',
        displayName: 'Soil Temperature Depth 7',
        convert: function (x) { return ((x[13] - 32768) / 100.0) },
        unit: '°C'
      },
      {
        name: 'soil_moisture_8',
        displayName: 'Soil Moisture Depth 8',
        convert: function (x) { return ((x[14] - 2500) / 500) },
        unit: ''
      },
      {
        name: 'soil_temperature_8',
        displayName: 'Soil Temperature Depth 8',
        convert: function (x) { return ((x[15] - 32768) / 100.0) },
        unit: '°C'
      }
    ]
  },
  {
    length: 1,
    values: [
      {
        name: 'battery_voltage',
        displayName: 'Battery Voltage',
        convert: function (x) { return (x[0] / 1000).toFixed(3) },
        unit: 'V'
      }
    ]
  }
]

function dlReadInt (bytes: number, pos: number): number {
  return (bytes[pos] << 8) + bytes[pos + 1]
}

const decentlabDecoder = {
  PROTOCOL_VERSION: 2,

  decode: function (msg, sensors): MeasurementLookup {
    let bytes = msg
    let i, j
    if (typeof msg === 'string') {
      bytes = []
      for (i = 0; i < msg.length; i += 2) {
        bytes.push(parseInt(msg.substring(i, i + 2), 16))
      }
    }

    const version = bytes[0]
    if (version !== this.PROTOCOL_VERSION) {
      console.log('ERROR', 'protocol version ' + version + ' doesn\'t match v2')
      return {} as MeasurementLookup
    }

    let flags = dlReadInt(bytes, 3)
    const result = {}
    // decode payload
    let pos = 5
    for (i = 0; i < sensors.length; i++, flags >>= 1) {
      if ((flags & 1) !== 1) {
        continue
      }

      const sensor = sensors[i]
      const x: number[] = []
      // convert data to 16-bit integer array
      for (j = 0; j < sensor.length; j++) {
        x.push(dlReadInt(bytes, pos))
        pos += 2
      }

      // decode sensor values
      for (j = 0; j < sensor.values.length; j++) {
        const value = sensor.values[j]
        if ('convert' in value) {
          result[value.displayName] = {
            Value: value.convert.bind(this)(x),
            Units: value.unit
          }
        }
      }
    }
    return result
  }
}

export default new DecoderService()
