<template>
  <Modal ref="editModal" :customer="customer" />
  <InfoPane
    class="dt-info-pane-sm"
    title="Customer Info"
    v-if="customer"
    :actionFunc="showEditModal"
    actionIcon="pen-to-square"
    itemType="Customer Info"
  >
    <template v-slot:info>
      <ul v-if="customer" class="info-pane-list">

        <li class="info-pane-list-item" v-if="customer.status === 'inactive'">
          <label class="info-item-label node-inactive-header">INACTIVE</label>
        </li>
        <li class="info-pane-list-item">
          <ElementIdRow label="ID" :value="customer.id" />
        </li>
        <li class="info-pane-list-item" v-if="customer.displayName">
          <span class="list-heading-label">
            Display Name:
          </span>
          <span>
            {{ customer.displayName }}
          </span>
        </li>
        <li class="info-pane-list-item" v-if="customer.aka">
          <span class="list-heading-label">
            Also Known As:
          </span>
          <span>
            {{ customer.aka }}
          </span>
        </li>
        <li class="info-pane-list-item" v-if="customer.classification">
          <span>
            <span class="list-heading-label">
              Classification:
            </span>
            <span class="capitalize">{{ customer.classification }}</span>
          </span>
        </li>
        <li class="info-pane-list-item node-notes node-customer-notes" v-if="customer.notes"><pre class="notes">{{ customer.notes }}</pre></li>
        <li class="info-pane-list-item" v-if="customer.contactName || customer.email || customer.phone">
          <div class="contact-row-item" v-if="customer.contactName">
            <span class="attribute-label">Point of Contact:</span> {{ customer.contactName }}
          </div>
          <div class="contact-row-item px-1 mb-2" v-if="customer.email">
            <a class="padded" v-bind:href="'mailto:' + customer.email">
              <fa icon="envelope" class="button-icon me-2" />
              {{ customer.email }}
            </a>
          </div>
          <div class="contact-row-item px-1" v-if="customer.phone">
            <a class="padded" v-bind:href="'tel:' + customer.phone">
              <fa icon="square-phone-flip" class="button-icon me-2" />
              {{ customer.phone }}
            </a>
          </div>
        </li>
        <li class="info-pane-list-item d-flex" v-if="customer.state">
          <div class="col address-box">
            <div class="node-info-row" v-if="customer.address1">{{ customer.address1 }}</div>
            <div class="node-info-row" v-if="customer.address2">{{ customer.address2 }}</div>
            <div class="node-info-row">
              <span class="address-city" v-if="customer.city">{{ customer.city }}</span>
              <span class="address-state" v-if="customer.state">{{ customer.state }}</span>
              <span class="address-zip" v-if="customer.postalCode">{{ customer.postalCode }}</span>
              <span class="address-country" v-if="customer.country">{{ customer.country }}</span>
            </div>
          </div>
        </li>
        <li class="info-pane-list-item d-flex align-items-center add-border-top">
          <div class="contact-row-item px-1">
            <router-link class="padded" :to="{ name: 'admin-app-user-access-customer', params: {customer: customer.id}}">
              <fa icon="user-shield" class="button-icon me-2" />
              Manage App/API Access for Customer
            </router-link>
          </div>
        </li>
        <li class="info-pane-list-item" v-if="customer.lastModified">
          <span class="subtle-info status-last-modified">
            {{ getSinceSecs(customer.lastModified) }}
          </span>
          <span class="subtle-info font-reduce-2" v-if="customer.lastModifiedBy"> by {{ customer.lastModifiedBy }}</span>
        </li>
      </ul>
    </template>
  </InfoPane>
</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'
import InfoPane from '@/components/tables/InfoPane.vue'
import Modal from '@/components/fleet/ModalAddUpdateCustomer.vue'
import ElementIdRow from '@/components/forms/ElementIdRow.vue'

export default {
  extends: BaseComponent,
  components: { InfoPane, ElementIdRow, Modal },
  props: ['customer'],
  methods: {
    showEditModal () {
      this.$refs.editModal.show()
    }
  }
}
</script>
