<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" :sz="modalSz">
      <template v-slot:header>
        {{ formTitle }}
      </template>

      <template v-slot:body>

        <div v-for="(field,i) of fields1" :key="i">
          <ElementIdRow v-if="field.controlType === 'id' && formData && formData.id"
            class="ps-2 mt-0 mb-3 pe-2 pb-2 add-border-bottom subtle"
            :label="field.label || 'ID'"
            :value="formData.id"
            :hideLabel="field.hideLabel || false"
          />
          <TextInputRow v-else-if="field.controlType === 'textInput'"
            :label="field.label"
            v-model="formData[field.field]"
            :labelClass="field.required ? 'label-required' : ''"
            :inputClass="field.inputClass"
            :required="field.required ? true : false"
            :placeholder="field.placeholder || ''"
            :dataType="field.dataType || ''"
            :suffix="field.suffix"
          />
        </div>

        <LastModifiedRow :ts="data.lastModified" :author="data.lastModifiedBy" v-if="data && 'lastModified' in data"/>

      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn" @click="submit()" :disabled="!canSubmit">Submit</button>
          <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="close()">Cancel</a>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Modal from '@/components/Modal.vue'
import ElementIdRow from '@/components/forms/ElementIdRow.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import LastModifiedRow from '@/components/forms/LastModifiedRow.vue'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

export default {
  name: 'modal-submission-form',
  props: ['title', 'sz', 'data', 'fields1', 'updateFunc', 'createFunc', 'elementType'],
  data () {
    return {
      isLoading: false,
      isBlocking: false,
      formData: {},
    }
  },
  computed: {
    action () {
      return this.isCreate ? 'Create' : 'Update'
    },
    element () {
      return this.elementType ? this.elementType : 'Item'
    },
    formTitle () {
      if (this.title) {
        return title
      }
      return `${this.action} ${this.element}`
    },
    isCreate () {
      return !this.data
    },
    modalSz () {
      if (!this.sz) {
        return 'sm'
      }
      return this.sz
    },
    canSubmit () {
      if (this.isLoading) {
        return false
      }
      if (!this.fields1) {
        return true
      }
      for (const field of this.fields1) {
        const isRequired = 'required' in field && field.required === true
        if (!isRequired) {
          continue
        }
        const fieldExists = field.field in this.formData && this.formData[field.field] !== ''
        if (!fieldExists) {
          return false
        }
      }
      return true
    },
    submitFunc () {
      if (this.isCreate && this.createFunc) {
        return this.createFunc
      }
      if (!this.isCreate && this.updateFunc) {
        return this.updateFunc
      }
      return undefined
    }
  },
  methods: {
    submit () {
      if (!this.submitFunc) {
        return
      }
      this.isLoading = true
      this.submitFunc(this.formData)
      .then(() => {
        HelpContent.setTimedFlashMessage(`${this.element} ${this.action}d`)
        this.$nextTick(() => {
          Reloadables.requestReload()
          this.close()
        })
      })
      .catch(e => {
        let message = `${this.element} ${this.action} Failed`
        if (e.response && e.response.data && e.response.data.message) {
          message += `: ${e.response.data.message}`
        }
        HelpContent.setFlashMessage(message, true)
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    reset () {
      this.isLoading = false
    },
    show () {
      this.reset()
      this.isBlocking = true
      if (this.data) {
        this.formData = { ...this.data }
        delete this.formData.lastModified
        delete this.formData.lastModifiedBy
        delete this.formData.createdAt
        delete this.formData.createdBy
      }
    },
    close () {
      this.formData = {}
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
  },
  components: { Modal, ElementIdRow, TextInputRow, LastModifiedRow }
}
</script>