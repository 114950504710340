<template>
  <transition name="modal">
    <modal v-if="isBlocking" @close="close()" sz="sm">

      <template v-slot:header>
        <span class="capitalize">Edit {{ site.siteClassification }}</span> Geography &amp; Location
      </template>

      <template v-slot:body>
        <div style="margin-bottom:-15px">
          <div class="d-flex justify-content-center">
            <div class="btn-group justify-items-center" role="group" aria-label="Location Tab">
              <input type="radio" class="btn-check" value="general" name="pane"
                id="pane-general" v-model="pane" autocomplete="off" />
              <label class="btn btn-outline-primary" for="pane-general">General</label>
              <input type="radio" class="btn-check" value="path" name="pane"
                id="pane-path" v-model="pane" autocomplete="off" />
              <label class="btn btn-outline-primary" for="pane-path">Map Polygon</label>
            </div>
          </div>
          <hr class="mt-3 mb-3" />
          <div class="container ps-0 pe-0 mx-auto">
            <div class="row ps-0">
              <div class="col mx-0 px-0 d-flex justify-content-center">
                <div class="site-node-add-form">

                  <div v-show="pane=='general'">
                    <GPSInputRow label="GPS" v-model="formData" />

                    <div class="row form-group">
                      <label class="col-form-label form-left-header" for="sizeAcres">Size</label>
                      <div class="col-auto form-right-input">
                        <input
                          type="text"
                          class="form-input"
                          id="sizeAcres"
                          v-model="sizeAcres"
                          name="sizeAcres"
                          style="width:55px;"
                          placeholder="53.82"
                          maxlength="6"
                          autocomplete="off"
                        />
                        <span class="ms-2 subtle">Acres</span>
                      </div>
                    </div>

                    <hr class="mt-0 mb-3" />

                    <div class="row form-group">
                      <label class="col-form-label form-left-header" for="address1">Address 1</label>
                      <div class="col-auto form-right-input">
                        <input
                          type="text"
                          class="form-input"
                          id="address1"
                          v-model="siteAddress1"
                          name="address1"
                          style="width:246px;"
                          placeholder="1209 DeSoto Ave."
                          maxlength="64"
                          autocomplete="off"
                        />
                      </div>
                    </div>

                    <div class="row form-group">
                      <label class="col-form-label form-left-header" for="address2">Address 2</label>
                      <div class="col-auto form-right-input">
                        <input
                          type="text"
                          class="form-input"
                          id="address2"
                          v-model="siteAddress2"
                          name="address2"
                          style="width:246px;"
                          placeholder="Suite C"
                          maxlength="64"
                          autocomplete="off"
                        />
                      </div>
                    </div>

                    <div class="row form-group">
                      <label class="col-form-label form-left-header" for="city">City</label>
                      <div class="col-auto form-right-input">
                        <input
                          type="text"
                          class="form-input"
                          id="city"
                          v-model="siteCity"
                          name="city"
                          style="width:246px;"
                          placeholder="Kannapolis"
                          maxlength="32"
                          autocomplete="off"
                        />
                      </div>
                    </div>

                    <div class="row form-group">
                      <label class="col-form-label form-left-header" for="state" v-if="!siteCountry || siteCountry === 'US'">State</label>
                      <label class="col-form-label form-left-header" for="state" v-else-if="siteCountry === 'IQ' || siteCountry === 'CA'">Province</label>
                      <div class="col-auto form-right-input pe-0">
                        <select class="form-select" id="state" v-model="siteState" name="state" style="width:90px;">
                          <StateOptions v-if="!siteCountry || siteCountry === 'US'" />
                          <IraqProvinceOptions v-else-if="siteCountry === 'IQ'" />
                          <CanadaProvinceOptions v-else-if="siteCountry === 'CA'" />
                        </select>
                      </div>
                      <label class="col-form-label form-left-header ps-0" style="width:50px" for="postalCode" v-if="!siteCountry || siteCountry === 'US'">Zip</label>
                      <label class="col-form-label form-left-header ps-0" style="width:50px" for="postalCode" v-else>Postal</label>
                      <div class="col-auto form-right-input" style="padding-left:8px">
                        <input
                          type="text"
                          class="form-input"
                          id="postalCode"
                          v-model="sitePostalCode"
                          name="postalCode"
                          style="width:100px;"
                          placeholder="28027-4737"
                          maxlength="10"
                          autocomplete="off"
                        />
                      </div>
                    </div>

                    <div class="row form-group">
                      <label class="col-form-label form-left-header" for="country">Country</label>
                      <div class="col-auto form-right-input">
                        <select class="form-select" id="country" v-model="siteCountry" name="country" style="width:65px;">
                          <CountryOptions />
                        </select>
                      </div>
                      <label class="col-form-label form-left-header ps-0 pe-2 label-required" style="width:40px" for="tz">TZ</label>
                      <select class="form-select" style="width:130px" id="tzs" v-model="siteTz" required>
                        <TimezoneOptions />
                      </select>
                    </div>

                    <div class="row form-group">
                      <label class="col-form-label form-left-header" for="displayUnits">App Units</label>
                      <div class="col-auto form-right-input">
                        <select class="form-select" id="displayUnits" v-model="siteDisplayUnits" name="displayUnits" style="width:246px;">
                          <DisplayUnitsOptions />
                        </select>
                      </div>
                    </div>

                    <hr class="info-sep mt-1 mb-3" />

                    <div class="row form-group mb-3 pb-0">
                      <label class="col-form-label form-left-header" for="accessInfo">Site Access</label>
                      <div class="col-auto form-right-input">
                        <textarea class="notes-box" v-model="accessInfo" id="accessInfo" style="height: 55px; width:246px;" placeholder="Use this space for gate codes and site access information."></textarea>
                      </div>
                    </div>

                  </div>

                  <div v-if="pane == 'path'">
                    <div class="mb-1">Paste in GeoJSON FeatureCollection:</div>
                    <div>
                      <TextAreaRow v-model="geojson" inputStyle="width:330px; height:400px" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="coordinates" class="text-center notable mt-3">
          <fa icon="check" class="flash-icon-svg me-2" />
          {{coordinatesType}} Coordinates are Valid
        </div>
        <div v-else-if="geojson && !coordinates" class="text-center warning mt-3">
          <fa icon="xmark" class="flash-icon-svg warning me-2" />
          Invalid GeoJSON Document
        </div>
      </template>
      <template v-slot:footer>
        <div class="row">
          <div class="col buttons-right" style="width:100%">
            <button class="btn" @click.prevent="updateSite()" :disabled="isLoading">Update</button>
            <a class="btn btn-blue" @click.prevent="close()">Cancel</a>
          </div>
        </div>
      </template>
    </modal>
  </transition>
</template>

<script>
/* eslint-disable */
import Modal from '@/components/Modal.vue'
import StateOptions from '@/components/selectOptions/StateOptions.vue'
import IraqProvinceOptions from '@/components/selectOptions/IraqProvinceOptions.vue'
import CanadaProvinceOptions from '@/components/selectOptions/CanadaProvinceOptions.vue'
import GPSInputRow from '@/components/forms/GPSInputRow.vue'
import CountryOptions from '@/components/selectOptions/CountryOptions.vue'
import DisplayUnitsOptions from '@/components/selectOptions/DisplayUnitsOptions.vue'
import TimezoneOptions from '@/components/selectOptions/TimezoneOptions.vue'
import TextAreaRow from '@/components/forms/TextAreaRow.vue'
import FleetDataService from '@/services/fleet/FleetDataService'
import HelpContent from '@/services/HelpContent'

export default {
  name: 'modal-edit-site-location',
  data () {
    return {
      isBlocking: false,
      accessInfo: '',
      sizeAcres: '',
      siteAddress1: '',
      siteAddress2: '',
      siteCity: '',
      siteState: '',
      sitePostalCode: '',
      siteCountry: '',
      siteDisplayUnits: '',
      siteTz: '',
      pane: 'general',
      isDirty: false,
      sitePath: [],
      geojson: '',
      isLoading: false,
      formData: {lat: undefined, long: undefined}
    }
  },
  props: ['site'],
  emits: ['reloadSite'],
  computed: {
    coordinatesType () {
      if (!this.coordinates) {
        return ''
      }
      if (this.coordinates.includes('|')) {
        return 'MultiPolygon'
      }
      return 'Polygon'
    },
    coordinates () {
      if (!this.geojson) {
        return ''
      }
      let isValid = false
      let obj = {}
      try {
        obj = JSON.parse(this.geojson)
        isValid = true
      } catch (e) {
        return ''
      }
      // For Polygon and MultiPolygon (not inside GeometryCollection)
      if (obj.type && obj.type === 'FeatureCollection' && obj.features &&
        obj.features.length > 0 && obj.features[0].geometry &&
        obj.features[0].geometry.type && obj.features[0].geometry.coordinates &&
        obj.features[0].geometry.coordinates.length > 0) {
        isValid = true
        const geometry = obj.features[0].geometry
        if (geometry.type === 'Polygon') {
          const list = []
          for (const i in geometry.coordinates[0]) {
            if (geometry.coordinates[0][i].length !== 2) {
              continue
            }
            list.push(`${geometry.coordinates[0][i][0]},${geometry.coordinates[0][i][1]}`)
          }
          return list.join(';')
        } else if (geometry.type === 'MultiPolygon') {
          const list = []
          for (const i in geometry.coordinates) {
            const lsub = []
            for (const j in geometry.coordinates[i][0]) {
              if (geometry.coordinates[i][0][j].length !== 2) {
                continue
              }
              lsub.push(`${geometry.coordinates[i][0][j][0]},${geometry.coordinates[i][0][j][1]}`)
            }
            list.push(lsub.join(';'))
          }
          return list.join('|')
        }
      }
      // For GeometryCollection
      if (obj.type && obj.type === 'FeatureCollection' && obj.features &&
        obj.features.length > 0 && obj.features[0].geometry &&
        obj.features[0].geometry.type && obj.features[0].geometry.geometries
        && obj.features[0].geometry.geometries.length > 0) {
        const geometry = obj.features[0].geometry.geometries[0]
        if (geometry.type === 'Polygon') {
          const list = []
          for (const i in geometry.coordinates[0]) {
            if (geometry.coordinates[0][i].length !== 2) {
              continue
            }
            list.push(`${geometry.coordinates[0][i][0]},${geometry.coordinates[0][i][1]}`)
          }
          return list.join(';')
        } else if (geometry.type === 'MultiPolygon') {
          const list = []
          for (const i in geometry.coordinates) {
            const lsub = []
            for (const j in geometry.coordinates[i][0]) {
              if (geometry.coordinates[i][0][j].length !== 2) {
                continue
              }
              lsub.push(`${geometry.coordinates[i][0][j][0]},${geometry.coordinates[i][0][j][1]}`)
            }
            list.push(lsub.join(';'))
          }
          return list.join('|')
        }
      }
      // For GeometryCollection 2
      if (obj.type && obj.type === 'GeometryCollection' && obj.geometries &&
        obj.geometries.length > 0 && obj.geometries[0].type &&
        obj.geometries[0].coordinates && obj.geometries[0].coordinates.length > 0) {
        const geometry = obj.geometries[0]
        if (geometry.type === 'Polygon') {
          const list = []
          for (const i in geometry.coordinates[0]) {
            if (geometry.coordinates[0][i].length !== 2) {
              continue
            }
            list.push(`${geometry.coordinates[0][i][0]},${geometry.coordinates[0][i][1]}`)
          }
          return list.join(';')
        } else if (geometry.type === 'MultiPolygon') {
          const list = []
          for (const i in geometry.coordinates) {
            const lsub = []
            for (const j in geometry.coordinates[i][0]) {
              if (geometry.coordinates[i][0][j].length !== 2) {
                continue
              }
              lsub.push(`${geometry.coordinates[i][0][j][0]},${geometry.coordinates[i][0][j][1]}`)
            }
            list.push(lsub.join(';'))
          }
          return list.join('|')
        }
      }
      return ''
    }
  },
  watch: {
    site: {
      handler () {
        this.populate()
      }
    }
  },
  methods: {
    updateSite () {
      if ((this.formData.lat !== '' && (this.formData.lat > 90 || this.formData.lat < -90)) ||
        (this.formData.long !== '' && (this.formData.long > 180 || this.formData.long < -180))) {
        HelpContent.setFlashMessage('Invalid Lat/long: ' + this.formData.lat + ',' + this.formData.long, true)
        return
      }
      this.isLoading = true
      const data = {}
      data.lat = this.formData.lat
      data.long = this.formData.long
      data.accessInfo = this.accessInfo
      data.sizeAcres = this.sizeAcres
      data.address1 = this.siteAddress1
      data.address2 = this.siteAddress2
      data.city = this.siteCity
      data.state = this.siteState
      data.country = this.siteCountry
      data.postalCode = this.sitePostalCode
      data.displayUnits = this.siteDisplayUnits
      data.tz = this.siteTz
      if (this.coordinates) {
        data.polygon = this.coordinates
      }
      FleetDataService.updateSite(this.site.id, data)
        .then(() => {
          HelpContent.setTimedFlashMessage('Site Geography & Location Updated')
          this.inEditMode = false
          this.$nextTick(() => {
            this.$emit('reloadSite')
          })
        })
        .catch(e => {
          let message = 'Site Location Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    reset () {
      this.isDirty = false
      this.accessInfo = ''
      this.sizeAcres = ''
      this.siteAddress1 = ''
      this.siteAddress2 = ''
      this.siteCity = ''
      this.siteState = ''
      this.sitePostalCode = ''
      this.siteDisplayUnits = ''
      this.siteCountry = ''
      this.siteTz = ''
      this.sitePath = []
    },
    populate () {
      if (!this.site) {
        return
      }
      if (this.site.lat !== 0 && this.site.long !== 0) {
        this.formData.lat = this.site.lat
        this.formData.long = this.site.long
      }
      if (this.site.address1) {
        this.siteAddress1 = this.site.address1
      }
      if (this.site.accessInfo) {
        this.accessInfo = this.site.accessInfo
      }
      if (this.site.sizeAcres) {
        this.sizeAcres = this.site.sizeAcres
      }
      if (this.site.address2) {
        this.siteAddress2 = this.site.address2
      }
      if (this.site.city) {
        this.siteCity = this.site.city
      }
      if (this.site.state) {
        this.siteState = this.site.state
      }
      if (this.site.postalCode) {
        this.sitePostalCode = this.site.postalCode
      }
      if (this.site.displayUnits) {
        this.siteDisplayUnits = this.site.displayUnits
      }
      if (this.site.country) {
        this.siteCountry = this.site.country
      }
      if (this.site.tz) {
        this.siteTz = this.site.tz
      }
      if (this.site.polygon) {
        const parts = this.site.polygon.split(';')
        const path = []
        for (const i in parts) {
          const subParts = parts[i].split(',')
          if (subParts.length === 2) {
            subParts[0] = parseFloat(subParts[0])
            subParts[1] = parseFloat(subParts[1])
            path.push(subParts)
          }
        }
        this.sitePath = path
      }
    },
    newPath () {
      this.sitePath.push(['', ''])
    },
    remPath (idx) {
      this.sitePath.splice(idx, 1)
    },
    bumpPath (idx) {
      if (idx <= 0) {
        return
      }
      const temp = this.sitePath[idx - 1]
      this.sitePath[idx - 1] = this.sitePath[idx]
      this.sitePath[idx] = temp
    },
    show () {
      this.reset()
      this.populate()
      this.isBlocking = true
    },
    close () {
      this.isBlocking = false
    },
  },
  components: { Modal, TextAreaRow, GPSInputRow, StateOptions, IraqProvinceOptions, CanadaProvinceOptions, CountryOptions, TimezoneOptions, DisplayUnitsOptions }
}
</script>
