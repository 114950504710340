<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm">
      <template v-slot:header>
        Remove Customer Domain
      </template>

      <template v-slot:body>
        <div>
          <p class="px-2 pb-3 add-border-bottom font-reduce-2 notable text-center">
            Press 'Confirm' to remove this Domain
          </p>
        </div>

        <form ref="customerForm" @submit.prevent="handleSubmit" autocomplete="off" style="margin-bottom:-12px">

          <TextLabelRow label="Customer"
            :value="customer.id"
            labelClass="width-95"
          />

          <TextLabelRow label="Domain"
            :value="domain"
            labelClass="width-95"
          />

        </form>
      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn btn-red" @click="updateCustomer()" :disabled="isLoading || !domain">Confirm</button>
          <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="close()">Cancel</a>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

import Modal from '@/components/Modal.vue'

import TextLabelRow from '@/components/forms/TextLabelRow.vue'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'customer-add',
  props: ['customer', 'domain'],
  data () {
    return {
      isBlocking: false,
      isLoading: false
    }
  },
  methods: {
    show () {
      if (this.customer && this.domain) {
        this.isBlocking = true
      }
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    updateCustomer () {
      const domain = this.domain.trim().toLowerCase()
      const formData = {}
      if (!this.customer.domains) {
        HelpContent.setFlashMessage(`No domains configured for ${this.customer.id}`, true)
        return
      } else {
        const domains = this.customer.domains.split(',')
        const index = domains.indexOf(domain)
        if (index === -1) {
          HelpContent.setFlashMessage(`${domain} is not configured for ${this.customer.id}`, true)
          return
        } else {
          domains.splice(index, 1)
        }
        formData.domains = domains.join(',')
      }
      this.isLoading = true
      FleetDataService.updateCustomer(this.customer.id, formData)
        .then(() => {
          HelpContent.setTimedFlashMessage(`${domain} successfully removed from ${this.customer.id}`)
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Customer Domains Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  components: { Modal, TextLabelRow }
}
</script>
