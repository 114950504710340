<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm">
      <template v-slot:header>
        {{ headerText }}
      </template>

      <template v-slot:body>
        <form ref="customerForm" @submit.prevent="handleSubmit" autocomplete="off" style="margin-top:-8px; margin-bottom:-12px">

          <div v-if="!isCreate" class="ps-2 mt-0 mb-3 pb-1 add-border-bottom subtle">
            {{ formData.id }}
          </div>

          <TextInputRow label="Display Name"
            v-model="formData.displayName"
            labelClass="label-required"
            :required="true"
            placeholder="Sandy Shore Vineyards"
            maxlength="72"
            dataType="description"
          />

          <SelectRow label="Status" v-model="formData.status" :required="true">
            <template v-slot:options>
              <option value="">-- Select --</option>
                <GeneralStatusOptions />
            </template>
          </SelectRow>

          <SelectRow label="Classification" v-model="formData.classification" :required="true">
            <template v-slot:options>
              <option value="">-- Select --</option>
                <option value="customer">Customer</option>
                <option value="grower">Grower</option>
                <option value="researcher">Researcher</option>
                <option value="partner">Partner</option>
                <option value="internal">Internal</option>
                <option value="demo">Demo</option>
            </template>
          </SelectRow>

          <TextAreaRow label="Notes"
            v-model="formData.notes"
            placeholder="Enter any info about this Customer that Agrology may find useful in the future."
            inputStyle="height: 67px;"
          />

          <TextInputRow label="AKA"
            v-model="formData.aka"
            placeholder="Customer Alias"
            maxlength="72"
            dataType="description"
          />

          <TextInputRow label="POC"
            v-model="formData.contactName"
            placeholder="Duke Ellington"
            maxlength="64"
          />

          <TextInputRow label="Phone"
            v-model="formData.phone"
            placeholder="888-555-1212"
            maxlength="18"
            dataType="phone"
          />

          <TextInputRow label="Email"
            v-model="formData.email"
            placeholder="me@awesome-domain.com"
            maxlength="48"
            dataType="email"
          />

          <hr class="form-section" />

          <AddressSection
            v-model="formData"
          />

        </form>
      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn" @click="updateCustomer()" v-if="!isCreate" :disabled="isLoading || !formData.classification || !formData.displayName || !formData.status">Submit</button>
          <button class="btn" @click="createCustomer()" v-else :disabled="isLoading|| !formData.classification || !formData.displayName || !formData.status">Submit</button>
          <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="close()">Cancel</a>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'
import Modal from '@/components/Modal.vue'

import TextInputRow from '@/components/forms/TextInputRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import TextAreaRow from '@/components/forms/TextAreaRow.vue'
import AddressSection from '@/components/forms/AddressSection.vue'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'customer-add',
  props: ['customer'],
  data () {
    return {
      isBlocking: false,
      formData: {},
      isCreate: false,
      mode: '',
      isLoading: false
    }
  },
  computed: {
    headerText () {
      if (this.customer) {
        return 'Edit Customer'
      }
      return 'Add New Customer'
    }
  },
  methods: {
    reset () {
      this.mode = ''
      if (this.customer) {
        this.formData = { ...this.customer }
      } else {
        this.isCreate = true
        this.formData = {}
      }
    },
    show () {
      this.reset()
      this.isBlocking = true
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    updateCustomer () {
      this.isLoading = true
      const name = this.formData.id
      delete this.formData.id
      this.formData.displayName = this.formData.displayName.trim()
      if (this.formData.notes) {
        this.formData.notes = this.formData.notes.trim()
      }
      FleetDataService.updateCustomer(name, this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Customer Updated')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          this.formData.id = name
          let message = 'Customer Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    createCustomer () {
      this.isLoading = true
      this.formData.displayName = this.formData.displayName.trim()
      FleetDataService.createCustomer(this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Customer Created')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Customer Creation Failed'
          if (e.response && e.response.data && e.response.data.message) {
            if (e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
              message += ': Customer Already Exists'
            } else {
              message += `: ${e.response.data.message}`
            }
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  components: { Modal, TextInputRow, SelectRow, TextAreaRow, AddressSection, GeneralStatusOptions }
}
</script>
