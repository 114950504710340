<template>
  <transition name="modal">
    <modal v-if="isBlocking" @close="close()" sz="sm">
        <template v-slot:header>
            QR Code Editor
        </template>
        <template v-slot:body>
            <QR />
        </template>
    </modal>
  </transition>
</template>

<script>
import Modal from '@/components/Modal.vue'
import HelpContent from '@/services/HelpContent'
import QR from '@/components/tools/QRCodeWidget.vue'

export default {
  data () {
    return {
      isBlocking: false
    }
  },
  components: { Modal, QR },
  methods: {
    show () {
      this.isBlocking = true
    },
    close () {
      this.isBlocking = false
    }
  }
}
</script>
