<template>
    <transition name="modal">
      <Modal ref="baseModal" v-if="isBlocking && role" @close="close()" sz="sm">
        <template v-slot:header>
          Add Role Privilege
        </template>

        <template v-slot:body>
          <form ref="roleForm" @submit.prevent="handleSubmit" autocomplete="off" style="margin-top:-8px; margin-bottom:-12px">

            <div class="my-4">
              <TextLabelRow label="Role"
                :value="role.label"
                valueClass="subtle"
              />

              <SelectRow
                label="Action"
                v-model="formData.verb"
                selectStyle="width: 235px;"
              >
                <template v-slot:options>
                  <option value="">-- Select Action --</option>
                  <option value="view">View</option>
                  <option value="update">Update (includes View)</option>
                  <option value="create">Create (includes View)</option>
                  <option value="edit">Edit (View/Create/Update)</option>
                  <option value=""></option>
                  <option value="*">ANY - use with caution</option>
                </template>
              </SelectRow>

              <SelectRow
                label="Subject"
                v-model="formData.subject"
                selectStyle="width: 235px;"
              >
                <template v-slot:options>
                  <option value="">-- Select Subject --</option>
                  <option value="sites">Sites</option>
                  <option value="nodes">Nodes</option>
                  <option value="crops-products">Crops &amp; Products</option>
                  <option value="devices">Devices</option>
                  <option value="gateways">Gateways</option>
                  <option value="makes">Makes &amp; Models</option>
                  <option value="dev-sw-vers">Device SW Versions</option>
                  <option value="nwservers">Network Servers</option>
                  <option value="senet-api">Senet Api</option>
                  <option value=""></option>
                  <option value="alerts">Monitoring Alerts</option>
                  <option value="thresholds">Monitoring Thresholds</option>
                  <option value="templates">Monitoring Templates</option>
                  <option value="subscriptions">Notification Subscriptions</option>
                  <option value="notif-exclusion-zones">Notification Exclusion Zones</option>
                  <option value=""></option>
                  <option value="ml-scripts">ML Scripts</option>
                  <option value="ml-script-versions">ML Script Versions</option>
                  <option value="ml-jobs">ML Jobs</option>
                  <option value="ml-job-schedules">ML Job Schedules</option>
                  <option value=""></option>
                  <option value="app-users">Mobile App Users</option>
                  <option value="staff-users">Staff Tools Access</option>
                  <option value="staff-roles">Staff Roles</option>
                  <option value=""></option>
                  <option value="*">ANY - use with caution</option>
                </template>
              </SelectRow>

              <SelectRow
                label="Customer"
                v-model="formData.customer"
                selectStyle="width: 235px;"
              >
                <template v-slot:options>
                  <option value="">-- Select Customer --</option>
                  <option v-for="cust in activeCustomers" :key="cust.id" :value="cust.id">
                    {{ cust.displayName }}
                  </option>
                  <option value=""></option>
                  <option value="*">ANY - use with caution</option>
                </template>
              </SelectRow>

            </div>
          </form>
        </template>
        <template v-slot:footer>
          <div class="col-auto buttons-right">
            <button class="btn" @click="createPriv()" :disabled="(isLoading || !formData.verb || !formData.subject || !formData.customer)">Add Privilege</button>
            <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="close()">Cancel</a>
          </div>
        </template>
      </Modal>
    </transition>
  </template>
<script>
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import AdminDataService from '@/services/admin/AdminDataService'
import FleetDataService from '@/services/fleet/FleetDataService'

import Modal from '@/components/Modal.vue'
import TextLabelRow from '@/components/forms/TextLabelRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'

export default {
  name: 'priv-add',
  props: ['role', 'successFunc'],
  data () {
    return {
      isBlocking: false,
      formData: {},
      isLoading: false,
      customers: []
    }
  },
  computed: {
    activeCustomers () {
      const out = []
      if (this.customers) {
        for (const cust of this.customers) {
          if (cust.status !== 'active') {
            continue
          }
          out.push(cust)
        }
      }
      return out
    }
  },
  methods: {
    reset () {
      this.formData = {}
    },
    show () {
      this.reset()
      this.isBlocking = true
      this.getCustomers()
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    getCustomers () {
      this.isLoading = true
      FleetDataService.listCustomers()
        .then(response => {
          this.customers = response.data
        })
        .catch(e => {
          let message = 'Failed to load Customer list'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    createPriv () {
      this.isLoading = true
      const data = {
        verb: this.formData.verb,
        subject: this.formData.subject,
        customer: this.formData.customer
      }
      AdminDataService.addStaffRolePriv(this.role.id, data)
        .then(() => {
          HelpContent.setTimedFlashMessage('Role Privilege Added')
          Reloadables.requestReload()
          if (this.successFunc) {
            this.successFunc()
          }
          this.close()
        })
        .catch(e => {
          let message = 'Role Privilege Creation Failed'
          if (e.response && e.response.data && e.response.data.message) {
            if (e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
              message += ': Role Privilege Already Exists'
            } else {
              message += `: ${e.response.data.message}`
            }
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  components: { Modal, TextLabelRow, SelectRow }
}
</script>
