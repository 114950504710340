<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking && job" @close="close()" sz="sm">
      <template v-slot:header>
        New Run Schedule Entry
      </template>

      <template v-slot:body>
        <div class="ms-2 me-3">
          <div>
            <table class="profile-table">
              <tr><th>Entry Type</th>
                <td>
                  <select class="form-select" style="width: 100%" v-model="entryType">
                    <option value=""> -- Select --</option>
                    <ScheduleTypes />
                  </select>
                </td>
              </tr>

              <tr v-if="entryType"><td colspan="2"><hr /></td></tr>

              <tr v-if="entryType === 'weekly'"><th>Day of Week</th>
                <td>
                  <select class="form-select" style="width: 100%" v-model="dow">
                    <option value=""> -- Select --</option>
                    <DowOpts />
                  </select>
                </td>
              </tr>

              <tr v-if="entryType === 'one-time'"><th>Year</th>
                <td>
                  <select class="form-select" style="width: 100%" v-model="yr">
                    <option value=""> -- Select --</option>
                    <YearOpts />
                  </select>
                </td>
              </tr>

               <tr v-if="entryType === 'one-time'"><th>Month</th>
                <td>
                  <select class="form-select" style="width: 100%" v-model="mn">
                    <option value=""> -- Select --</option>
                    <MonthOpts />
                  </select>
                </td>
              </tr>

               <tr v-if="entryType === 'monthly' || entryType === 'one-time'"><th>Day</th>
                <td>
                  <select class="form-select" style="width: 100%" v-model="dy">
                    <option value=""> -- Select --</option>
                    <DayOpts />
                  </select>
                </td>
              </tr>

              <tr v-if="entryType && entryType !== 'hourly'"><th>Hour</th>
                <td>
                  <select class="form-select" style="width: 100%" v-model="hr">
                    <option value=""> -- Select --</option>
                    <HourOpts />
                  </select>
                </td>
              </tr>

              <tr v-if="entryType"><th>Minute</th>
                <td>
                  <select class="form-select" style="width: 100%" v-model="mt">
                    <option value=""> -- Select --</option>
                    <MinOpts />
                  </select>
                </td>
              </tr>

            </table>
          </div>

        </div>
      </template>

      <template v-slot:footer>
        <span class="notable me-4">Times are in UTC</span>
        <button class="btn ms-2" @click="addEntry()" :disabled="!canAdd()">
          Add Entry
        </button>
        <button class="btn btn-blue ms-2" @click="close()">
          Cancel
        </button>
      </template>
    </Modal>
  </transition>
</template>

<script>
import Modal from '@/components/Modal.vue'
import BaseComponent from '@/components/BaseComponent.vue'
import ScheduleTypes from '@/components/selectOptions/ModelingScheduleTypes.vue'
import DowOpts from '@/components/selectOptions/DayOfWeekOptions.vue'
import YearOpts from '@/components/selectOptions/YearOptions.vue'
import MonthOpts from '@/components/selectOptions/MonthOptions.vue'
import DayOpts from '@/components/selectOptions/DayOfMonthOptions.vue'
import HourOpts from '@/components/selectOptions/HourOptions.vue'
import MinOpts from '@/components/selectOptions/MinuteOptions.vue'
import ModelingDataService from '@/services/ModelingDataService'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

export default {
  extends: BaseComponent,
  data () {
    return {
      entryType: '',
      dow: '',
      yr: '',
      mn: '',
      dy: '',
      hr: '',
      mt: '',
      isLoading: false,
      isBlocking: false
    }
  },
  props: ['job'],
  components: { Modal, ScheduleTypes, DowOpts, YearOpts, MonthOpts, DayOpts, HourOpts, MinOpts },
  computed: {
    mts () {
      return this.secsFrom(this.mt, 60)
    },
    hrs () {
      return this.secsFrom(this.hr, 3600)
    },
    dys () {
      return this.secsFrom(this.dy, 86400)
    },
    dows () {
      return this.secsFrom(this.dow, 86400)
    }
  },
  methods: {
    secsFrom (input, multiplier) {
      const val = parseInt(input)
      if (isNaN(val)) {
        return val
      }
      return val * multiplier
    },
    canAdd () {
      return !this.isLoading && ((this.entryType === 'one-time' && this.yr && this.mn && this.dy && this.hr && this.mt) ||
     (this.entryType === 'monthly' && this.dy && this.hr && this.mt) ||
     (this.entryType === 'weekly' && this.dow && this.hr && this.mt) ||
     (this.entryType === 'daily' && this.hr && this.mt) ||
     (this.entryType === 'hourly' && this.mt))
    },
    addEntry () {
      let offset = -1
      if (this.entryType === 'one-time') {
        offset = (Date.parse(`${this.yr}-${this.mn}-${this.dy}T${this.hr}:${this.mt}:00.000Z`)) / 1000
      } else if (this.entryType === 'monthly') {
        offset = this.dys + this.hrs + this.mts
      } else if (this.entryType === 'weekly') {
        offset = this.dows + this.hrs + this.mts
      } else if (this.entryType === 'daily') {
        offset = this.hrs + this.mts
      } else if (this.entryType === 'hourly') {
        offset = this.mts
      }
      if (offset === -1) {
        return
      }
      this.isLoading = true
      ModelingDataService.addScheduleEntry(this.job.name, this.entryType, offset)
        .then(() => {
          HelpContent.setTimedFlashMessage('Schedule Entry Added')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Add Schedule Entry Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    reset () {
      this.dow = ''
      this.yr = ''
      this.mn = ''
      this.dy = ''
      this.hr = ''
      this.mt = ''
      this.entryType = ''
    },
    show () {
      this.reset()
      this.isBlocking = true
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    }
  }
}
</script>
