<template>
  <transition name="modal">
    <div class="spreadsheet-mask" v-if="isBlocking" @close="close()">
      <div class="spreadsheet-wrapper">
        <div class="spreadsheet-container">
          <div class="d-flex justify-content-between" style="max-width:100vw;height:40px;margin-top:5px;">
            <div style="overflow:auto;white-space:nowrap">
              <h4 style="font-size:22px;" class="pt-1 ps-2">
                {{ titleText }}
                <a @click="close()" class="btn btn-blue me-3 ms-3 d-md-none">Close</a>
              </h4>
            </div>
            <div class="d-none d-md-inline">
              <!-- <a class="btn">Download CSV</a>
              <a class="btn ms-3">Download TSV</a> -->
              <a @click="close()" class="btn btn-blue me-3">Close</a>
            </div>
          </div>
          <div class="spreadsheet-table-wrapper">
            <table class="spreadsheet-table" v-if="parsedData.length > 0">
              <thead>
                <tr class="header">
                  <th class="header-col" v-if="numberedRows" />
                  <th v-for="(field,j) of parsedData[0]" :key="j" :class="{'header-col': !numberedRows && j === 0}">
                    {{ field }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(line,i) of parsedData" :key="i" v-bind:class="{'d-none': i == 0, 'data-row-last': i+1 == parsedData.length}">
                  <td class="header-col" v-if="numberedRows">{{ i }}</td>
                  <td v-for="(field,j) of line" :key="j" :class="{'header-col': !numberedRows && j === 0}">
                    {{ field }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data () {
    return {
      isBlocking: false
    }
  },
  props: ['sheetData', 'sheetTitle', 'numberedRows'],
  computed: {
    titleText () {
      return this.sheetTitle
    },
    parsedData () {
      if (!this.sheetData) {
        return []
      }
      const parsed = []
      const lines = this.sheetData.trim().split('\n')
      for (const line of lines) {
        const fields = line.split('\t')
        parsed.push(fields)
      }
      return parsed
    }
  },
  methods: {
    show () {
      this.isBlocking = true
    },
    close () {
      this.isBlocking = false
    }
  }
}
</script>
