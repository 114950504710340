<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm">
      <template v-slot:header>
        {{ headerText }}
      </template>

      <template v-slot:body>
        <form ref="firmwareForm" @submit.prevent="handleSubmit" autocomplete="off" style="margin-top:-8px; margin-bottom:-12px">

          <TextLabelRow v-if="!isCreate" label="Make / Model"
            :value="formData.make + ' / ' + formData.model"
            valueClass="subtle ms-1"
          />

          <TextLabelRow label="Make / Model"
            :value="make + ' / ' + model"
            valueClass="subtle ms-1" v-else
          />

          <TextInputRow label="Version"
            v-model="formData.version"
            :readonly="!isCreate"
            :inputClass="{'subtle-info': !isCreate}"
            :labelClass="{'label-required': isCreate}"
            :required="isCreate"
            placeholder="12.3.16"
            maxlength="24"
            dataType="semver"
            inputStyle="width: 100px"
          />

         <CheckboxInputRow
            label=""
            v-model="formData.isDefault"
            checkboxLabel="Default Version for this Model"
            id="cbIsDefault"
          />

          <hr class="form-section" />

          <SelectRow
            class="mt-3"
            label="Status"
            v-model="formData.status"
            :required="true"
            selectStyle="width: 235px;"
          >
            <template v-slot:options>
              <option value="">-- Select --</option>
              <GeneralStatusOptions />
            </template>
          </SelectRow>

          <TextInputRow label="Description"
            v-model="formData.description"
            placeholder="Ground Truth Firmware v12.3.16"
            maxlength="36"
            dataType="description"
            inputStyle="width:235px;"
          />

          <hr class="form-section" />

          <div v-if="version && version.fileLocation" class="mb-4">

            <FormFieldRow label="Firmware File">
              <template v-slot:input>
                <div style="height:32px; margin-top: 4px;">
                  <span class="subtle">
                    <fa icon="check" class="flash-icon-svg me-2" />
                    Uploaded
                  </span>
                </div>
              </template>
            </FormFieldRow>

            <FormFieldRow label="Replace">
              <template v-slot:input>
                <div style="height:32px;margin-top: 3px;">
                  <button class="btn btn-short" v-if="displayFileName === 'Attach .bin/.zip File'">
                    <label title="Select Firmware" for="firmwareFileReplace">{{ displayFileName }}</label>
                  </button>
                  <div style="height:32px;margin-top: 3px;" v-else>
                    <label class="font-reduce-2 me-3">{{ displayFileName }}</label>
                    <button class="btn btn-short px-2">
                      <label title="Select Firmware" for="firmwareFileReplace">Change</label>
                    </button>
                  </div>
                  <input class="d-none" type="file" id="firmwareFileReplace" @change="filesChanged($event)" accept=".bin,.zip" />
                </div>
              </template>
            </FormFieldRow>

            <div v-if="selectedFileName !== ''" class="text-center">
              <span class="status-warning ms-2 font-reduce-2">
                <fa icon="triangle-exclamation" class="svg-icon-left" />
                This will replace the deployed {{ formData.version }} Firmware
              </span>
            </div>
          </div>

          <FormFieldRow label="Firmware" :required="true" v-else>
            <template v-slot:input>
              <div style="height:32px;margin-top: 3px;">
                <button class="btn btn-short" v-if="displayFileName === 'Attach .bin/.zip File'">
                  <label title="Select Firmware" for="firmwareFile">{{ displayFileName }}</label>
                </button>
                <div style="height:32px;margin-top: 3px;" v-else>
                  <label class="font-reduce-2 me-3">{{ displayFileName }}</label>
                  <button class="btn btn-short px-2">
                    <label title="Select Firmware" for="firmwareFile">Change</label>
                  </button>
                </div>
                <input class="d-none" type="file" id="firmwareFile" @change="filesChanged($event)" accept=".bin,.zip" required />
              </div>
            </template>
          </FormFieldRow>

        </form>
      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn" @click="updateFirmware()" :disabled="isLoading || !formData.status" v-if="!isCreate">Submit</button>
          <button class="btn" @click="createFirmware()" :disabled="isLoading || !formData.status || !formData.version || !selectedFileName" v-else>Submit</button>
          <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="close()">Cancel</a>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Modal from '@/components/Modal.vue'

import CheckboxInputRow from '@/components/forms/CheckboxInputRow.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import TextLabelRow from '@/components/forms/TextLabelRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import FormFieldRow from '@/components/forms/FormFieldRow.vue'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'

import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'firmware-add-update',
  props: ['version', 'make', 'model'],
  data () {
    return {
      isBlocking: false,
      formData: {},
      isCreate: false,
      isLoading: false,
      mode: '',
      selectedFileName: ''
    }
  },
  computed: {
    displayFileName () {
      if (this.selectedFileName) {
        return this.selectedFileName
      }
      return 'Attach .bin/.zip File'
    },
    headerText () {
      if (this.version) {
        return 'Edit Firmware Version'
      }
      return 'Add New Firmware Version'
    }
  },
  methods: {
    filesChanged (e) {
      if (!e.target.files || e.target.files.length === 0) {
        this.selectedFileName = ''
        delete this.formData.fileBase64
        return
      }
      const files = e.target.files
      for (const file of files) {
        const reader = new FileReader()
        if (file) {
          reader.addEventListener('load', () => {
            this.selectedFileName = file.name
            this.formData.filename = file.name
            this.formData.fileBase64 = btoa(reader.result)
          }, false)
          reader.readAsBinaryString(file)
        }
        break // only care about the first file
      }
    },
    reset () {
      this.mode = ''
      this.selectedFileName = ''
      if (this.version) {
        this.formData = { ...this.version }
      } else {
        this.isCreate = true
        this.formData = {}
      }
    },
    show () {
      this.reset()
      this.isBlocking = true
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    updateFirmware () {
      this.isLoading = true
      const updateForm = {
        status: this.formData.status,
        description: this.formData.description,
        isDefault: this.formData.isDefault,
        filename: this.formData.filename
      }
      if (this.formData.fileBase64) {
        updateForm.fileBase64 = this.formData.fileBase64
      }
      FleetDataService.updateFirmwareVersion(this.version.make,
        this.version.model, this.version.version, updateForm)
        .then(() => {
          Reloadables.requestReload()
          HelpContent.setTimedFlashMessage('Firmware Version was updated')
          this.close()
        })
        .catch(e => {
          let message = 'Failed to Update Firmware Version'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    createFirmware () {
      this.isLoading = true
      const createForm = {
        version: this.formData.version,
        status: this.formData.status,
        description: this.formData.description,
        isDefault: this.formData.isDefault,
        fileBase64: this.formData.fileBase64
      }
      FleetDataService.createFirmwareVersion(this.make, this.model, createForm)
        .then(() => {
          Reloadables.requestReload()
          HelpContent.setTimedFlashMessage('Firmware Version was created')
          this.close()
        })
        .catch(e => {
          let message = 'Failed to Create Firmware Version'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  components: { Modal, TextInputRow, TextLabelRow, FormFieldRow, SelectRow, GeneralStatusOptions, CheckboxInputRow }
}
</script>
