import http from '@/http-common'

class ModelingDataService {
  listAllJobs () {
    return http.get('/modeling/jobs')
  }

  retrieveJob (id: string) {
    return http.get(`/modeling/jobs/${id}`)
  }

  addJob (data) {
    return http.post('/modeling/jobs', data)
  }

  updateJob (id: string, data) {
    return http.put(`/modeling/jobs/${id}`, data)
  }

  listAllScripts () {
    return http.get('/modeling/scripts')
  }

  retrieveScript (id: string) {
    return http.get(`/modeling/scripts/${id}`)
  }

  addScript (data) {
    return http.post('/modeling/scripts', data)
  }

  updateScript (id: string, data) {
    return http.put(`/modeling/scripts/${id}`, data)
  }

  listScriptVersions (id: string) {
    return http.get(`/modeling/scripts/${id}/versions`)
  }

  addScriptVersion (id: string, data) {
    return http.post(`/modeling/scripts/${id}/versions`, data)
  }

  updateScriptVersion (id: string, version: string, data) {
    return http.put(`/modeling/scripts/${id}/versions/${version}`, data)
  }

  publishScriptVersion (id: string, version: string) {
    return http.get(`/modeling/scripts/${id}/versions/${version}/publish`)
  }

  listJobRuns (id: string) {
    return http.get(`/modeling/jobs/${id}/runs`)
  }

  getRunDataSignedLinks (id: string, startTime: number) {
    return http.get(`/modeling/jobs/${id}/runs/${startTime}/links`)
  }

  listJobSchedule (id: string) {
    return http.get(`/modeling/jobs/${id}/schedule`)
  }

  addScheduleEntry (id: string, entryType: string, offset: number) {
    return http.post(`/modeling/jobs/${id}/schedule`, { entryType: entryType, offsetSecs: offset })
  }

  deleteScheduleEntry (id: string, entryType: string, offset: number) {
    return http.delete(`/modeling/jobs/${id}/schedule/${entryType}/${offset}`)
  }

  startNewAdHocRun (data) {
    return http.post('/modeling/runs', data)
  }

  cancelJobRun (id: string, startTime: number) {
    return http.delete(`/modeling/jobs/${id}/runs/${startTime}`)
  }
}

export default new ModelingDataService()
