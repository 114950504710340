<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm">
      <template v-slot:header>
        Send Senet Downlink
      </template>

      <template v-slot:body>
        <div>
          <p class="px-2 pb-3 add-border-bottom font-reduce-2 subtle">
            Sending an invalid downlink PDU can be dangerous.
            <br />
            Verify the payload and EUIs before submitting.
          </p>
        </div>

        <form ref="customerForm" @submit.prevent="handleSubmit" autocomplete="off">

          <SelectRow
            label="Format"
            labelClass="label-required width-95"
            selectStyle="width:250px;"
            v-model="fmt"
          >
            <template v-slot:options>
              <option value="">-- Select Payload Format --</option>
              <option value="dl">DecentLab</option>
              <option value="raw">Raw</option>
            </template>
          </SelectRow>

          <TextInputRow label="Parameter"
            v-model="dlp"
            labelClass="label-required width-95"
            inputStyle="text-transform: lowercase; width:60px;"
            placeholder="0000"
            maxlength="4"
            dataType="hex"
            v-if="fmt === 'dl'"
          />

          <TextInputRow label="Value"
            v-model="dlv"
            labelClass="label-required width-95"
            inputStyle="text-transform: lowercase; width:60px;"
            placeholder="0000"
            maxlength="4"
            dataType="hex"
            v-if="fmt === 'dl'"
          />

          <TextLabelRow label="CRC"
            labelClass="width-95"
            :value="dlCRC"
            v-if="fmt === 'dl'"
          />

          <TextInputRow label="PDU"
            v-model="pduIn"
            labelClass="label-required width-95"
            inputStyle="text-transform: lowercase; width:250px;"
            placeholder="0000"
            maxlength="32"
            dataType="hex"
            v-if="fmt && fmt !== 'dl'"
          />

          <hr v-if="fmt" />

          <TextAreaRow label="EUIs"
            v-model="euis"
            labelClass="label-required width-95"
            placeholder="1 EUI per line"
            inputStyle="height: 120px; width:250px"
            class="mt-3 pt-2"
            v-if="fmt"
          />

        </form>
      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn" @click="updateCustomer()" :disabled="isLoading || !pdu || !euis">Send Downlinks</button>
          <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="close()">Cancel</a>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

import Modal from '@/components/Modal.vue'

import TextInputRow from '@/components/forms/TextInputRow.vue'
import TextLabelRow from '@/components/forms/TextLabelRow.vue'
import TextAreaRow from '@/components/forms/TextAreaRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'

import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'customer-add',
  props: ['customer'],
  data () {
    return {
      isBlocking: false,
      fmt: '',
      dlp: '',
      dlv: '',
      pduIn: '',
      euis: '',
      isLoading: false
    }
  },
  computed: {
    pdu () {
      if (!this.fmt) {
        return ''
      }
      // if raw, return pdu
      if (this.fmt === 'raw') {
        return this.pduIn
      }
      // otherwise, if not decentlab, abort
      if (this.fmt !== 'dl') {
        return ''
      }
      // validate decentlab input
      if (!this.dlCRC || this.dlCRC.length !== 4) {
        return ''
      }
      return this.dlp.toUpperCase() + this.dlv.toUpperCase() + this.dlCRC
    },
    dlCRC () {
      if (!this.dlp || !this.dlv || this.dlp.length !== 4 || this.dlv.length !== 4) {
        return ''
      }
      const n1 = Number('0x' + this.dlp.substring(0, 2))
      const n2 = Number('0x' + this.dlp.substring(2, 4))
      const n3 = Number('0x' + this.dlv.substring(0, 2))
      const n4 = Number('0x' + this.dlv.substring(2, 4))
      const buffer = [n1, n2, n3, n4]
      var crc = 0xFFFF
      var odd
      for (var i = 0; i < buffer.length; i++) {
        crc = crc ^ buffer[i]
        for (var j = 0; j < 8; j++) {
          odd = crc & 0x0001
          crc = crc >> 1
          if (odd) {
            crc = crc ^ 0xA001
          }
        }
      }
      return crc.toString(16).toUpperCase()
    }
  },
  methods: {
    show () {
      this.isBlocking = true
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    updateCustomer () {
      // const domain = this.domain.trim().toLowerCase()
      // const formData = {}
      // if (!this.customer.domains) {
      //   formData.domains = domain
      // } else {
      //   const domains = this.customer.domains.split(',')
      //   if (domains.includes(domain)) {
      //     HelpContent.setFlashMessage(`${domain} is already configured for ${this.customer.id}`, true)
      //     return
      //   }
      //   domains.push(domain)
      //   formData.domains = domains.join(',')
      // }
      // this.isLoading = true
      // FleetDataService.updateCustomer(this.customer.id, formData)
      //   .then(() => {
      //     HelpContent.setTimedFlashMessage(`${domain} successfully added to ${this.customer.id}`)
      //     Reloadables.requestReload()
      //     this.close()
      //   })
      //   .catch(e => {
      //     let message = 'Customer Domains Update Failed'
      //     if (e.response && e.response.data && e.response.data.message) {
      //       message += `: ${e.response.data.message}`
      //     }
      //     HelpContent.setFlashMessage(message, true)
      //   })
      //   .finally(() => {
      //     this.isLoading = false
      //   })
    }
  },
  components: { Modal, TextInputRow, SelectRow, TextLabelRow, TextAreaRow }
}
</script>
