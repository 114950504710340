<template>

  <Spreadsheet ref="spreadsheet" v-bind:sheetTitle="sheetTitle" v-bind:sheetData="sheetData" />
  <SenetWidget ref="senetWidget" isInputMode="1" />
  <ModalBulkUpdateImport ref="modalBulkUpdateImport" />
  <ModalManageNodeLayouts ref="modalManageDeviceGroupLayouts" />
  <ModalSendSenetDownlink ref="senetDownlinkWidget" />

  <div class="row justify-content-center mx-0 px-0 pt-3">

    <InfoPane paneClass="fleet-misc-info-pane" class="fleet-misc-info-pane mb-3" v-if="hasNWServers || hasMakes || isAdmin || hasProducts">
      <template v-slot:info>
        <ul class="info-pane-list">
          <li class="info-pane-list-item">
              <h5 class="mt-2 ps-2">Additional Fleet Entities</h5>
          </li>
          <li class="info-pane-list-item height-50 d-flex ps-0" v-if="hasNWServers">
            <router-link :to="{  name: 'network-list' }" class="info-pane-item-link hoverable">Manage Network Servers</router-link>
          </li>
          <li class="info-pane-list-item height-50 d-flex ps-0" v-if="hasMakes">
            <router-link :to="{  name: 'manufacturer-list' }" class="info-pane-item-link hoverable">Manage Manufacturers &amp; Models</router-link>
          </li>
          <li class="info-pane-list-item height-50 d-flex ps-0" v-if="isAdmin">
            <router-link :to="{  name: 'firmware-list' }" class="info-pane-item-link hoverable">Manage Firmware</router-link>
          </li>
          <li class="info-pane-list-item height-50 d-flex ps-0" v-if="isAdmin">
            <router-link :to="{  name: 'chamber-configs-list' }" class="info-pane-item-link hoverable">Manage Chamber &amp; Vent Configs</router-link>
          </li>
          <li class="info-pane-list-item height-50 d-flex ps-0 d-none d-lg-flex" v-if="isAdmin">
            <a @click="$refs.modalManageDeviceGroupLayouts.show()" class="info-pane-item-link hoverable">Manage Node Layouts</a>
          </li>
          <li class="info-pane-list-item height-50 d-flex ps-0" v-if="isAdmin">
            <router-link :to="{  name: 'fleet-taz' }" class="info-pane-item-link hoverable">Manage Flashing Appliances (Taz)</router-link>
          </li>
          <li class="info-pane-list-item height-50 d-flex ps-0" v-if="hasProducts">
            <router-link :to="{  name: 'products-list' }" class="info-pane-item-link hoverable">Manage Products, Crops &amp; Varietals</router-link>
          </li>
        </ul>
      </template>
    </InfoPane>

    <div class="col me-3 d-none d-sm-block" style="max-width:0px;width:0px"/>

    <InfoPane paneClass="fleet-misc-info-pane" class="fleet-misc-info-pane mb-auto">
      <template v-slot:info>
        <ul class="info-pane-list">
          <li class="info-pane-list-item">
              <h5 class="mt-2 ps-2">Fleet Inventory Tools</h5>
          </li>
          <li class="info-pane-list-item">
            <div class="col pt-1" style="text-align:center;">
              <div>Agrology Device Manifest</div>
              <div class="mt-3 mb-3">
                <a @click="getExportSpreadsheet()" class="btn">Spreadsheet</a>
                <a @click="getExportDevicesCSV()" class="btn ms-3"><fa icon="download" class="button-icon-wrapped" />CSV</a>
                <a @click="getExportDevicesTSV()" class="btn ms-3 me-3"><fa icon="download" class="button-icon-wrapped" />TSV</a>
              </div>
              <div class="pt-1">
                <span class="status-warning"><fa icon="triangle-exclamation" class="svg-icon-left" />Contains sensitive information</span>
              </div>
            </div>
          </li>
          <li class="info-pane-list-item d-none d-lg-block">
            <div class="row mx-0 px-0 pt-3 pb-3 no-border-bottom justify-content-center">
              <div class="col-auto">
                <button class="btn" @click="$refs.modalBulkUpdateImport.show()">Bulk Import / Update Devices</button>
              </div>
            </div>
          </li>
        </ul>
      </template>
    </InfoPane>

    <div class="col me-3 d-none d-sm-block" style="max-width:0px;width:0px"/>

    <InfoPane paneClass="fleet-misc-info-pane" class="fleet-misc-info-pane mb-auto" v-if="hasSenet">
      <template v-slot:info>
        <ul class="info-pane-list">
          <li class="info-pane-list-item">
              <h5 class="mt-2 ps-2">Senet Tools</h5>
          </li>
          <li class="info-pane-list-item">
            <div class="col pt-1" style="text-align:center;">
              <div class="pb-1">Senet Device Manifest</div>

              <div class="mt-2 pt-2 add-border-top add-border-bottom mb-2 pb-2">
                <div class="form-check form-check-inline radio-control">
                  <input class="form-check-input" type="radio"  id="nzPrivate" v-model="nz" value="private" >
                  <label class="form-check-label font-reduce-1" for="nzPrivate">
                    Private
                  </label>
                </div>
                <div class="form-check form-check-inline radio-control">
                  <input class="form-check-input" type="radio"  id="nzPublic" v-model="nz" value="public" >
                  <label class="form-check-label font-reduce-1" for="nzPublic">
                    Public
                  </label>
                </div>
              </div>

              <div class="mt-3 mb-3">
                <a @click="getSenetSpreadsheet()" class="btn">Spreadsheet</a>
                <a @click="getSenetDevicesCSV()" class="btn ms-3"><fa icon="download" class="button-icon-wrapped" />CSV</a>
                <a @click="getSenetDevicesTSV()" class="btn ms-3 me-3"><fa icon="download" class="button-icon-wrapped" />TSV</a>
              </div>
              <div class="pt-1">
                <span class="status-warning"><fa icon="triangle-exclamation" class="svg-icon-left" />Contains sensitive information</span>
              </div>
            </div>
          </li>
          <li class="info-pane-list-item">
            <div class="row mx-0 px-0 pt-3 pb-3 no-border-bottom justify-content-center">
              <div class="col-auto">
                <a @click="showSenetWidget()" class="btn">Senet Device Manager Widget</a>
                <br />
                <!-- <a @click="showSenetDownlinkWidget()" class="btn mt-4">Send Downlinks via Senet</a> -->
              </div>
            </div>
          </li>
        </ul>
      </template>
    </InfoPane>

  </div>
</template>
<script>
import FleetDataService from '@/services/fleet/FleetDataService'

import InfoPane from '@/components/tables/InfoPane.vue'
import Spreadsheet from '@/components/tables/Spreadsheet'
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'

import SenetWidget from '@/components/fleet/SenetWidget.vue'
import ModalBulkUpdateImport from '@/components/fleet/ModalBulkUpdateImport.vue'
import ModalSendSenetDownlink from '@/components/fleet/ModalSendSenetDownlink.vue'
import ModalManageNodeLayouts from '@/components/fleet/ModalManageNodeLayouts.vue'

export default {
  name: 'misc-main',
  components: { Spreadsheet, InfoPane, SenetWidget, ModalBulkUpdateImport, ModalManageNodeLayouts, ModalSendSenetDownlink },
  extends: BaseFleetComponent,
  data () {
    return {
      senetUrlCSV: this.VUE_APP_API_URL + '/devices/senet/all/csv?download=senet_device',
      sheetTitle: '',
      sheetData: '',
      nz: 'public'
    }
  },
  methods: {
    showSenetWidget () {
      this.$refs.senetWidget.show()
    },
    showSenetDownlinkWidget () {
      this.$refs.senetDownlinkWidget.show()
    },
    getExportSpreadsheet () {
      this.sheetTitle = 'Backend Device Manifest'
      FleetDataService.getDeviceExportTSV()
        .then(response => {
          this.sheetData = response.data
          this.$refs.spreadsheet.show()
        })
    },
    getSenetSpreadsheet () {
      this.sheetTitle = 'Senet Device Manifest'
      FleetDataService.getAllSenetDevicesCSV(this.nz, true, true)
        .then(response => {
          this.sheetData = response.data
          this.$refs.spreadsheet.show()
        })
    },
    getSenetDevicesCSV () {
      FleetDataService.getAllSenetDevicesCSV(this.nz, true, false)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'senet_devices.csv')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch(e => {
          console.log(e)
        })
    },
    getSenetDevicesTSV () {
      FleetDataService.getAllSenetDevicesCSV(this.nz, true, true)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'senet_devices.tsv')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch(e => {
          console.log(e)
        })
    },
    getExportDevicesCSV () {
      FleetDataService.getDeviceExportCSV()
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'agrology_fleet.csv')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch(e => {
          console.log(e)
        })
    },
    getExportDevicesTSV () {
      FleetDataService.getDeviceExportTSV()
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'agrology_fleet.tsv')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }
}

</script>
