<template>
  <div v-if="majorError">
    <h5 class="app-failure">{{ majorError }}</h5>
  </div>
  <ModalGatewayCredentials ref="modalGatewayCredentials" :gatewayEui="gateway.id" :site="gateway.site" v-if="gateway" />
  <InfoPane
    class="dt-info-pane-sm"
    :title="gateway.id"
    v-if="gateway"
    :actionFunc="toggleEditMode"
    actionIcon="pen-to-square"
    itemType="GW"
  >
    <template v-slot:info>
      <ul v-if="!inEditMode && gateway" class="info-pane-list">

        <li class="info-pane-list-item" v-if="gateway.status === 'inactive'">
          <div class="col-auto node-inactive-header">
            INACTIVE
          </div>
        </li>

        <li class="info-pane-list-item">
          <span class="list-heading-label me-2">Production/Monitored:</span>
          <span v-if="gateway.site && !gateway.monitoringMuted">
            <fa icon="check" class="flash-icon-svg me-2" />
          </span>
          <span v-else>
            <fa icon="xmark" class="map-icon status-warning" />
          </span>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="gateway.report_time">
          <div class="col node-general-header-105">
            Last Seen:
          </div>
          <div class="col-auto" v-bind:class="{'status-error': !gateway.monitoringMuted && isTimeAlertable(gateway.report_time), 'status-caution': !gateway.monitoringMuted && isTimeCautionable(gateway.report_time) }">
              {{ getSince(gateway.report_time) }}
          </div>
        </li>

        <li class="info-pane-list-item" v-if="gateway.notes">
          <div class="col node-notes node-general-notes">
            <pre class="notes">{{ gateway.notes }}</pre>
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="gateway.lastDeviceEUI">
          <div class="col node-general-header-105">
            Last Device:
          </div>
          <div class="col-auto">
            <router-link
              class="padded"
              :to="{  name: 'device-details', params: { id: gateway.lastDeviceEUI } }">
              {{ gateway.lastDeviceEUI }}
            </router-link>
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center">
          <div class="col node-general-header-105">
            Site:
          </div>
          <div class="col-auto" v-if="gateway.site">
            <router-link class="padded" :class="{'inactive': site && site.status === 'inactive'}"
              :to="{  name: 'site-details', params: { id: gateway.site } }">
              {{ siteDisplayName }}
            </router-link>
            <span class="inactive" v-if="site && site.status === 'inactive'"> (inactive)</span>
          </div>
          <div class="col-auto" v-else>
            <span class="label-need-info">
              <fa icon="exclamation" class="map-icon status-danger" />
              Site Association Needed
            </span>
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center">
          <div class="col-auto node-general-header-105">
            GPS:
          </div>
          <div class="col-auto" v-if="gateway.lat && gateway.long">
            {{ trunc(gateway.lat, 10, '') }}, {{ trunc(gateway.long, 10, '') }}
          </div>
          <div class="col-auto" v-else>
            <span class="label-need-info">
              <fa icon="exclamation" class="map-icon status-danger" />
              GPS Information Needed
            </span>
          </div>
          <!-- <div class="col d-flex justify-content-end">
            <span>
              <a :href="mapURL" target="gateway-loc-map">
                <fa icon="location-dot" class="button-icon" />
              </a>
            </span>
          </div> -->
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="gateway.manufacturer">
          <div class="col node-general-header-105">
            Make:
          </div>
          <div class="col-auto">
            <router-link class="padded" :class="{'inactive': manufacturer && manufacturer.status === 'inactive'}"
              :to="{  name: 'manufacturer-details', params: { id: gateway.manufacturer } }">
              {{ gateway.manufacturer }}
            </router-link>
            <span class="inactive" v-if="manufacturer && manufacturer.status === 'inactive'"> (inactive)</span>
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="gateway.model">
          <div class="col node-general-header-105">
            Model:
          </div>
          <div class="col-auto">
            {{ gateway.model }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="gateway.powerSource">
          <div class="col node-general-header-105">
            Power:
          </div>
          <div class="col-auto">
            {{ gateway.powerSource }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="gateway.networkServer">
          <div class="col node-general-header-105">
            LoRa Server:
          </div>
          <div class="col-auto">
            <router-link class="padded" :class="{'inactive': networkServer && networkServer.status === 'inactive'}"
              :to="{  name: 'network-details', params: { id: gateway.networkServer } }">
              {{ gateway.networkServer }}
            </router-link>
            <span class="inactive" v-if="networkServer && networkServer.status === 'inactive'"> (inactive)</span>
          </div>
            <div class="col d-flex justify-content-end" v-if="gateway.networkServer == 'aws-iot'">
              <span>
                <a @click="showAwsGwWidget()">
                  <fa icon="wrench" class="button-icon" />
                </a>
              </span>
            </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="gateway.networkAccess">
          <div class="col node-general-header-105">
            Internet:
          </div>
          <div class="col-auto">
            {{ gateway.networkAccess }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="gateway.networkAccess == 'cellular' && gateway.carrier">
          <div class="col node-general-header-105">
            Carrier:
          </div>
          <div class="col-auto">
            {{ gateway.carrier }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="gateway.networkAccess == 'cellular' && gateway.imei">
          <div class="col node-general-header-105">
            IMEI:
          </div>
          <div class="col-auto">
            {{ gateway.imei }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="gateway.networkAccess == 'cellular' && gateway.iccid">
          <div class="col node-general-header-105">
            ICCID:
          </div>
          <div class="col-auto">
            {{ gateway.iccid }}
          </div>
        </li>

        <li class="info-pane-list-item" v-if="gateway.lastModified">
          <span class="subtle-info status-last-modified">
            {{ getSinceSecs(gateway.lastModified) }}
          </span>
          <span class="subtle-info font-reduce-2" v-if="gateway.lastModifiedBy"> by {{ gateway.lastModifiedBy }}</span>
        </li>

      </ul>

      <form @submit.prevent="handleSubmit" class="node-form no-border" autocomplete="off" v-else>

        <div id="v-model-sites" class="row form-group">
          <label class="col-form-label form-left-header width-95 label-required" for="sites">Site</label>
          <div class="col-auto form-right-input">
            <select class="form-select" v-model="gateway.site" id="sites" style="width: 225px" required>
              <option v-for="site in activeSites" :value="site.id" :key="site.id">
                {{ site.displayName }}
              </option>
            </select>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label width-95 form-left-header" for="status">Status</label>
          <div class="col-auto form-right-input">
            <select class="form-select" v-model="gateway.status" style="width: 100px" required>
              <GeneralStatusOptions />
            </select>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-auto form-right-input" style="margin-left: 95px;">
            <input type="checkbox" class="form-check-input me-2" id="monitoringMuted" v-model="isMuted" @click="updateMuting()" />
            <label for="monitoringMuted">Suppress Monitoring Alerts</label>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label width-95 form-left-header" for="notes">Notes</label>
          <div class="col-auto form-right-input">
            <textarea
              id="notes"
              v-model="gateway.notes"
              name="notes"
              class="notes-box"
              placeholder="Enter any info about this Gateway that Agrology may find useful in the future."
            />
          </div>
        </div>

        <hr class="form-section"/>

        <div class="row form-group">
          <label class="col-form-label width-95 form-left-header" for="lat">GPS</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="lat"
              v-model="gateway.lat"
              name="lat"
              style="width:90px;"
              placeholder="-1.23456"
              maxlength="12"
            /> ,
            <input
              type="text"
              id="long"
              v-model="gateway.long"
              name="long"
              style="width:90px;margin-right:10px"
              placeholder="-1.23456"
              maxlength="12"
            />
          <a @click="getLocation()"><fa icon="compass" class="button-icon" /></a>
          </div>
        </div>

        <hr class="form-section"/>

        <div id="v-model-manufacturers" class="row form-group">
          <label class="col-form-label form-left-header width-95 label-required" for="manufacturers">Make</label>
          <div class="col-auto form-right-input">
            <select class="form-select" v-model="gateway.manufacturer" id="manufacturers" style="width: 225px" required>
              <option v-for="manufacturer in activeManufacturers" :value="manufacturer.id" :key="manufacturer.id">
                {{ manufacturer.id }}
              </option>
            </select>
          </div>
        </div>

        <div id="v-model-mfmodels" class="row form-group">
          <label class="col-form-label form-left-header width-95 label-required" for="mfmodels">Model</label>
          <div class="col-auto form-right-input">
            <select class="form-select" v-model="gateway.model" id="mfmodels" style="width: 225px" required>
              <option v-for="(mfmodel) in models" :value="mfmodel" :key="mfmodel">
                {{ mfmodel.trim() }}
              </option>
            </select>
          </div>
        </div>

        <div id="v-model-powers" class="row form-group">
          <label class="col-form-label form-left-header width-95 label-required" for="powers">Power</label>
          <div class="col-auto form-right-input">
            <select class="form-select" v-model="gateway.powerSource" id="powers" style="width: 225px" required>
              <PowerSourceOptions />
            </select>
          </div>
        </div>

        <hr />

        <div id="v-model-networkServers" class="row form-group">
          <label class="col-form-label form-left-header width-95 label-required" for="networkServers">LoRa Server</label>
          <div class="col-auto form-right-input">
            <select class="form-select" v-model="gateway.networkServer" id="networkServers" style="width: 225px" required>
              <option v-for="networkServer in activeNetworkServers" :value="networkServer.id" :key="networkServer.id">
                {{ networkServer.id }}
              </option>
            </select>
          </div>
        </div>

        <div id="v-model-networkAccesses" class="row form-group">
          <label class="col-form-label form-left-header width-95 label-required" for="networkAccesses">Internet</label>
          <div class="col-auto form-right-input">
            <select class="form-select" v-model="gateway.networkAccess" id="networkAccesses" style="width: 225px" required>
              <NetworkConnections />
            </select>
          </div>
        </div>

        <div id="v-model-carrier" class="row form-group" v-if="gateway.networkAccess == 'cellular'">
          <label class="col-form-label form-left-header width-95" for="carrier">Carrier</label>
          <div class="col-auto form-right-input">
            <select class="form-select" v-model="gateway.carrier" id="carrier" style="width: 225px">
              <CarrierOptions />
            </select>
          </div>
        </div>

        <div class="row form-group" v-if="gateway.networkAccess == 'cellular'">
          <label class="col-form-label form-left-header width-95" for="imei">IMEI</label>
          <div class="col-auto form-right-input">
            <input
              class="form-input"
              type="text"
              id="imei"
              v-model="gateway.imei"
              name="imei"
              style="width:180px;"
              placeholder="00000000000000"
              maxlength="28"
            />
          </div>
        </div>

        <div class="row form-group" v-if="gateway.networkAccess == 'cellular'">
          <label class="col-form-label form-left-header width-95" for="iccid">ICCID</label>
          <div class="col-auto form-right-input">
            <input
              class="form-input"
              type="text"
              id="iccid"
              v-model="gateway.iccid"
              name="iccid"
              style="width:220px;"
              placeholder="00000000000000"
              maxlength="28"
            />
          </div>
        </div>

        <div class="row-auto mx-0 px-0 form-button-row">
          <div class="col-auto buttons-right">
            <button class="btn" :disabled="isLoading">Update</button>
            <button class="btn btn-blue" @click.prevent="handleCancel()">Cancel</button>
          </div>
        </div>

      </form>

    </template>
  </InfoPane>

</template>

<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import FleetDataService from '@/services/fleet/FleetDataService'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'
import PowerSourceOptions from '@/components/selectOptions/PowerSourceOptions.vue'
import NetworkConnections from '@/components/selectOptions/NetworkConnections.vue'
import CarrierOptions from '@/components/selectOptions/CarrierOptions.vue'
import ModalGatewayCredentials from '@/components/fleet/ModalGatewayCredentials.vue'
import InfoPane from '@/components/tables/InfoPane.vue'
import HelpContent from '@/services/HelpContent'

export default {
  name: 'gateway-info',
  extends: BaseFleetComponent,
  data () {
    return {
      gatewayBeforeEdit: {},
      inEditMode: false
    }
  },
  methods: {
    trunc (input, length, suffix) {
      if (!input) {
        return ''
      }
      return this.truncate(input.toString(), length, suffix)
    },
    getLocation () {
      if (!this.gateway || !navigator.geolocation) {
        return
      }
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
      navigator.geolocation.getCurrentPosition(this.updateLocation, this.geoError, options)
    },
    updateLocation (pos) {
      if (!this.gateway) {
        return
      }
      this.gateway.lat = pos.coords.latitude
      this.gateway.long = pos.coords.longitude
    },
    toggleEditMode () {
      this.inEditMode = !this.inEditMode
    },
    showAwsGwWidget () {
      this.$refs.modalGatewayCredentials.show()
    },
    updateMuting () {
      if (this.gateway && this.isMuted) {
        this.gateway.monitoringMuted = 0
      } else {
        this.gateway.monitoringMuted = 1
      }
    },
    getGateway (id) {
      FleetDataService.getGateway(id)
        .then(response => {
          for (var r of this.sites) {
            if (r.id === response.data.site) {
              this.site = r
              break
            }
          }
          for (var m of this.manufacturers) {
            if (m.id === response.data.manufacturer) {
              this.manufacturer = m
              break
            }
          }
          for (var ns of this.networkServers) {
            if (ns.id === this.networkServer) {
              this.networkServer = ns
              break
            }
          }
          this.gateway = response.data
          this.gatewayBeforeEdit = Object.assign({}, this.gateway)
          this.$state.creationName = this.gateway.id
        })
        .catch(e => {
          this.majorError = ''
          this.handleError(e, 'Gateway')
        })
    },
    handleSubmit () {
      if ((this.gateway.lat !== '' && (this.gateway.lat > 90 || this.gateway.lat < -90)) ||
        (this.gateway.long !== '' && (this.gateway.long > 180 || this.gateway.long < -180))) {
        HelpContent.setFlashMessage('Invalid Lat/long: ' + this.gateway.lat + ',' + this.gateway.long, true)
        return
      }
      this.isLoading = true
      if (this.gateway.notes) {
        this.gateway.notes = this.gateway.notes.trim()
      }
      FleetDataService.updateGateway(this.gateway.id, this.gateway)
        .then(response => {
          HelpContent.setTimedFlashMessage('Gateway Updated')
          this.setNavGateway(this.$route.params.id)
          this.getGateway(this.gateway.id)
          this.inEditMode = false
        })
        .catch(e => {
          let message = 'Gateway Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleCancel () {
      Object.assign(this.gateway, this.gatewayBeforeEdit)
      this.inEditMode = false
    }
  },
  mounted () {
    this.setNavGateway(this.$route.params.id)
    this.retrieveAllNetworkServers()
    this.retrieveAllSites()
    this.retrieveAllManufacturers()
    this.getGateway(this.$route.params.id)
  },
  computed: {
    siteDisplayName () {
      if (!this.gateway) {
        return ''
      }
      return FleetDataService.getSiteDisplayName(this.gateway.site)
    },
    isMuted () {
      if (!this.gateway) {
        return false
      }
      return this.gateway.monitoringMuted === 1
    },
    models () {
      if (this.gateway) {
        if (this.gateway.manufacturer) {
          for (var m of this.activeManufacturers) {
            if (m.id === this.gateway.manufacturer) {
              return m.models.split(',')
            }
          }
        }
      }
      return {}
    }
  },
  components: { InfoPane, GeneralStatusOptions, PowerSourceOptions, NetworkConnections, CarrierOptions, ModalGatewayCredentials }
}
</script>

<style>
  .btn-blue {
    margin-left: 20px;
  }
</style>
