<template>
    <div>
        <FleetMapMenu @updateSettings="updateSettings" />
        <div>
          <Mapbox :height="mapHeight" :center="{lat: 38, lng: -122}" :zoom="6.7" :markers="markers" :gateways="gateways"/>
        </div>
    </div>
</template>
<script>
import Mapbox from '@/components/tools/Mapbox.vue'
import FleetMapMenu from '@/components/fleet/FleetMapMenu.vue'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'FleetMap',
  components: {
    Mapbox,
    FleetMapMenu
  },
  data () {
    return {
      show: '',
      showGW: '',
      mapHeight: (window.innerHeight - 200) + 'px',
      isActive: true
    }
  },
  methods: {
    updateHeightLoop () {
      if (!this.isActive) {
        return
      }
      this.mapHeight = (window.innerHeight - 200) + 'px'
      setTimeout(this.updateHeightLoop, 150)
    },
    updateSettings () {
      const show = localStorage.getItem('prefs.fleetmap-node-scope')
      const showGW = localStorage.getItem('prefs.fleetmap-gw-scope')
      if (!show) {
        this.show = 'all'
      } else {
        this.show = show
      }
      if (!showGW) {
        this.showGW = 'all'
      } else {
        this.showGW = showGW
      }
    },
    updateSearchData () {
      if (FleetDataService.manifest.data) {
        return
      }
      FleetDataService.getSearchIndexURL()
        .then(resp => {
          if (resp.data && resp.data.url) {
            FleetDataService.getSearchIndex(resp.data.url)
              .then(response => {
                FleetDataService.setManifest(response.data)
              })
              .catch(e => {
                console.log('error downloading index file', e)
              })
          }
        })
        .catch(e => {
          console.log('failed to fetch search index url', e)
        })
    }
  },
  mounted () {
    this.updateSettings()
    this.updateSearchData()
    this.updateHeightLoop()
  },
  unmounted () {
    this.isActive = false
  },
  computed: {
    gateways () {
      const list = []
      if (!FleetDataService.manifest.data) {
        return list
      }
      for (const key in FleetDataService.manifest.data) {
        if (key.startsWith('gateway.')) {
          const gw = FleetDataService.manifest.data[key]
          if (gw.lat !== undefined && gw.lat !== 0 && gw.long !== undefined && gw.long !== 0) {
            var color = '#333'
            var status = 1
            if (gw.status === 'unmonitored') {
              color = '#cccccc'
              status = 0
            } else if (gw.status === 'warning') {
              color = '#eeaa00'
              status = 2
            } else if (gw.status === 'error') {
              color = '#cc0000'
              status = 3
            }
            if ((this.showGW !== 'all' && status < 2) || this.showGW === 'none') {
              continue
            }
            var detail = ''
            if (gw.statusDetail) {
              detail = ': ' + gw.statusDetail
            }
            list.push({ coords: [gw.long, gw.lat], title: gw.siteName + ' - GW ' + gw.title, description: gw.status + detail, color: color, status: status })
          }
        }
      }
      return list
    },
    markers () {
      const list = []
      if (!FleetDataService.manifest.data) {
        return list
      }
      const nodes = {}
      for (const key in FleetDataService.manifest.data) {
        if (key.startsWith('device.')) {
          const d = FleetDataService.manifest.data[key]
          if (d.lat !== undefined && d.lat !== 0 && d.long !== undefined && d.long !== 0 && d.site && d.node) {
            var node = nodes[d.node]
            var nodeName = d.node
            const manifestKey = 'node.' + d.node
            if (manifestKey in FleetDataService.manifest.data) {
              nodeName = FleetDataService.manifest.data[manifestKey].title
            }
            var color = '#009900'
            var status = 1
            if (d.status === 'unmonitored') {
              color = '#cccccc'
              status = 0
            } else if (d.status === 'warning') {
              color = '#eeaa00'
              status = 2
            } else if (d.status === 'error') {
              color = '#cc0000'
              status = 3
            }
            if ((this.show !== 'all' && status < 2) || this.show === 'none') {
              continue
            }
            var detail = ''
            if (!node) {
              if (d.statusDetail) {
                detail = ': ' + d.statusDetail
              }
              node = { coords: [d.long, d.lat], title: d.siteName + ' - ' + nodeName, description: 'Device ' + d.title + ' - ' + d.status + detail, color: color, status: status }
            } else {
              if (d.statusDetail) {
                detail = ': ' + d.statusDetail
              }
              node.description += '\nDevice ' + d.title + ' - ' + d.status + detail
              if (status > node.status) {
                node.color = color
                node.status = status
              }
            }
            nodes[d.node] = node
          }
        }
      }
      for (const key in nodes) {
        const n = nodes[key]
        list.push({ coords: n.coords, title: n.title, description: n.description, color: n.color })
      }
      return list
    }
  }
}
</script>
