<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm">
      <template v-slot:header>
        <span class="font-reduce-1">Irrigation @ {{ site.id }}</span>
      </template>

      <template v-slot:body>

        <div class="px-2">
          <div class="mb-3">Enter known irrigation info as freeform text.</div>
          <form ref="irrigationForm" @submit.prevent="handleSubmit" autocomplete="off">
            <textarea v-model="formData.irrigationSummary"
              class="form-input" style="width:350px;height:90px;"
            />
          </form>
        </div>
      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn" @click="updateIrrigation()" :disabled="isLoading">Submit</button>
          <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="close()">Cancel</a>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import Modal from '@/components/Modal.vue'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'site-irrigation-edit',
  props: ['site'],
  data () {
    return {
      isBlocking: false,
      formData: {},
      isLoading: false
    }
  },
  methods: {
    reset () {
      this.mode = ''
      if (this.site) {
        this.formData = { ...this.site }
        this.isCreate = false
      } else {
        this.formData = {}
      }
    },
    show () {
      this.reset()
      this.isBlocking = true
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    updateIrrigation () {
      this.isLoading = true
      const data = { irrigationSummary: this.formData.irrigationSummary }
      FleetDataService.updateSiteIrrigation(this.site.id, data)
        .then(() => {
          HelpContent.setTimedFlashMessage('Irrigation Updated')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Irrigation Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  components: { Modal }
}
</script>
