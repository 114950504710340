<template>
  <header>
    <NavBar />
    <Flash />
  </header>

  <div v-if="isSignedIn" class="container" id="app-area">
    <router-view :key="$route.fullPath"/>
  </div>

  <footer>
    <Footer />
  </footer>

</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import Flash from '@/components/Flash.vue'

export default {
  name: 'App',
  components: { NavBar, Footer, Flash },
  mounted () {
    var theme = localStorage.getItem('prefs.theme')
    if (!theme) {
      theme = 'darkMode'
    }
    document.documentElement.setAttribute('data-theme', theme)
  },
  computed: {
    isSignedIn () {
      return this.$state.isSignedIn
    },
    userInfo () {
      return this.$state.email
    }
  }
}
</script>
