<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking && run" @close="close()" sz="auto">
      <template v-slot:header>
        Modeling Job - Run Info
      </template>

      <template v-slot:body>
        <div class="mx-2">
          <div class="mb-4" v-if="mode === 'confirm-cancel'">
            <h5 class="mb-4">Just a Quick Confirmation</h5>
            Canceling this run will terminate it immediately.
            <br /><br />
            No logs or data will be collected.
            <br /><br />
            Press 'Cancel this Run' again to proceed.
          </div>
          <div v-else>
            <table class="profile-table">
              <tr><th>Job Name</th><td>{{ run.jobName }}</td></tr>
              <tr><th>Script Name</th><td>{{ run.scriptName }}</td></tr>
              <tr><th>Script Version</th><td>{{ run.scriptVersion }}</td></tr>
              <tr><th style="vertical-align:top">Args</th><td>{{ run.args }}</td></tr>
              <tr v-if="run.instance >= 0"><th>Instance ID</th><td>{{ run.instance }}</td></tr>
              <tr><td class="rows-sep" colspan="2"><hr /></td></tr>
              <tr><th>Status</th><td>{{ run.status }}</td></tr>
              <tr><th>Start</th><td>{{ getFormattedDateSecs(run.startTime) }}</td></tr>
              <tr v-if="run.endTime"><th>End</th><td>{{ getFormattedDateSecs(run.endTime) }}</td></tr>
              <tr v-if="run.endTime"><th>Duration</th><td>{{ getSinceSecs(run.endTime - run.startTime) }}</td></tr>
              <tr v-if="run.detail"><th>Detail</th><td>{{ run.detail }}</td></tr>
              <tr v-if="run.status === 'completed' && run.endTime"><td class="rows-sep" colspan="2"><hr /></td></tr>
              <tr v-if="run.status === 'completed' && run.endTime"><th class="pt-1">Graphs</th><td class="pt-1"><a href="#" target="__NEW_EXT_">View in Dashboard <fa class="icon-text-link" icon="up-right-from-square" /></a></td></tr>
              <tr v-if="run.status === 'completed' && run.endTime"><th class="pt-3">CSV Results</th><td class="pt-3"><button class="btn btn-short me-2">Download <fa class="icon-text-link" icon="download" /></button></td></tr>
            </table>
            <div v-if="run.status === 'completed' || run.status === 'failed'">
              <hr class="form-section mt-3" />
              <span style="font-weight:bold">Job Run Artifacts</span>
              <div class="mt-2" v-if="isLoading && !links">
                <fa icon="hourglass" class="map-icon" />
                Loading ...
              </div>
              <ul class="file-arrow-down-list font-reduce-3 ms-1 px-0" style="list-style:none">
                <li class="my-1" v-for="link, k in links" :key="k">
                  <a :href="link" class="normal padded">
                    <fa icon="file-arrow-down" class="icon-text-link me-1 app-text-color" />
                    {{ baseName(k) }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <button class="btn btn-green ms-2" @click="runAgain" :disabled="isLoading" v-if="run.status === 'completed' || run.status === 'failed' || run.status === 'canceled'">
          Re-Run
        </button>
        <button class="btn btn-red ms-2" @click="confirmCancelRun" :disabled="isLoading" v-if="run.status === 'running' || run.status === 'starting'">
          Cancel this Run
        </button>
        <button class="btn btn-blue ms-2" @click="close">
          Close
        </button>
      </template>
    </Modal>
  </transition>
</template>

<script>
import Modal from '@/components/Modal.vue'
import BaseComponent from '@/components/BaseComponent.vue'
import ModelingDataService from '@/services/ModelingDataService'
import HelpContent from '@/services/HelpContent'
import Reloadables from '@/services/reloadables'

export default {
  extends: BaseComponent,
  data () {
    return {
      isBlocking: false,
      links: null,
      mode: null,
      modalSz: 'sm',
      oldRoute: null,
      isLoading: false
    }
  },
  props: ['run', 'reloadFunc'],
  components: { Modal },
  watch: {
    run: {
      handler () {
        this.loadLinks()
      }
    }
  },
  methods: {
    confirmCancelRun () {
      if (!this.run) {
        return
      }
      if (this.mode !== 'confirm-cancel') {
        this.mode = 'confirm-cancel'
        return
      }
      this.cancelRun()
    },
    cancelRun () {
      if (this.mode === 'confirm-cancel') {
        this.isLoading = true
        ModelingDataService.cancelJobRun(this.run.jobName, this.run.startTime)
          .then(() => {
            Reloadables.requestReload()
            HelpContent.setTimedFlashMessage('Job Run has been canceled.')
            this.close()
          })
          .catch(e => {
            let message = 'Failed to cancel Job Run'
            if (e.response && e.response.data && e.response.data.message) {
              message += `: ${e.response.data.message}`
            }
            HelpContent.setFlashMessage(message, true)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    runAgain () {
      if (!this.run) {
        return
      }
      this.isLoading = true
      const runConfig = {
        jobName: this.run.jobName,
        scriptName: this.run.scriptName,
        scriptVersion: this.run.scriptVersion,
        concurrency: 1
      }
      if (this.run.args) {
        const argsParts = this.run.args.replace('  ', ' ').split(' ')
        let skipNext = false
        let concurrencyNext = false
        let hasConcurrency = false
        const out = []
        for (const arg of argsParts) {
          if (skipNext) {
            skipNext = false
            continue
          }
          if (concurrencyNext) {
            runConfig.concurrency = parseInt(arg)
            concurrencyNext = false
            hasConcurrency = true
            continue
          }
          if (arg === '--instance') {
            skipNext = true
            continue
          }
          if (arg === '--concurrency') {
            concurrencyNext = true
            continue
          }
          out.push(arg)
        }
        runConfig.args = out.join(' ')
      }
      ModelingDataService.startNewAdHocRun(runConfig)
        .then(() => {
          HelpContent.setTimedFlashMessage('Re-run has been scheduled.')
        })
        .catch(e => {
          let message = 'Unable to schedule the Re-run'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          if (this.reloadFunc) {
            this.reloadFunc()
          }
          this.isLoading = false
          this.close()
        })
    },
    baseName (input) {
      if (!input.includes('/')) {
        return input
      }
      const parts = input.split('/')
      return parts[parts.length - 1]
    },
    loadLinks () {
      if (!this.run || (this.run.status !== 'completed' && this.run.status !== 'failed')) {
        this.links = null
        return
      }
      this.isLoading = true
      ModelingDataService.getRunDataSignedLinks(this.run.jobName, this.run.startTime)
        .then(result => {
          this.links = result.data
        })
        .catch(e => {
          let message = 'Failed to get the list of Run Artifacts'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    show () {
      this.links = null
      this.mode = null
      this.isBlocking = true
      this.$nextTick(() => {
        let path = this.$route.href
        let st = path.indexOf('/run/')
        if (st > 0) {
          path = path.substring(0, st)
        }
        st = path.indexOf('/version/')
        if (st > 0) {
          path = path.substring(0, st)
        }
        this.oldRoute = path
        history.pushState(
          {},
          null,
          path + '/run/' + this.run.startTime
        )
        this.loadLinks()
      })
    },
    close () {
      this.$refs.baseModal.close()
      this.isLoading = false
      this.isBlocking = false
      history.pushState(
        {},
        null,
        this.oldRoute
      )
    }
  }
}
</script>
