<template>
  <transition name="modal">
    <modal v-if="isBlocking" @close="close()" tabindex="0" sz="sm">

      <template v-slot:header>
        Credentials &amp; Links for AWS IoT
      </template>

      <template v-slot:body>

        <div class="mx-3">
          <div class="row ms-5">
            <div class="col-auto node-general-header">
              GW EUI
            </div>
            <div class="col">
              {{ gatewayEui }}
            </div>
          </div>

          <div class="row ms-5">
            <div class="col-auto node-general-header">
              Site
            </div>
            <div class="col">
              {{ site }}
            </div>
          </div>

          <hr class="my-3" v-if="!certPEM" />

          <div class="row" style="justify-content: center" v-if="!certPEM">
            <div class="col-auto" style="text-align:center;min-width:340px;">
              <span>You need to provide a one-time access code to download keys &amp; certs.</span>
              <hr class="my-3" />
              <a class="btn mb-2" @click="requestAccess()">Email Me an Access Code</a>
              <br />
              <label class="my-1">or</label>
              <br />
              <div class="mt-2">
                Enter Code:
                <input type="text" class="form-input ms-1 ps-2" style="width: 110px" v-model="code" />
                <button class="btn ms-2" :disabled="isLoading" @click="verifyAccess()" style="max-height:28px;padding-top:3px;margin-top:-3px;">Verify</button>
              </div>
            </div>
          </div>

          <hr class="my-3" v-if="certPEM" />

          <div class="row" v-if="certPEM" style="justify-content: center">
            <div class="col-auto" style="text-align:center;min-width:330px;">
              <a class="btn mb-2 px-2" @click="downloadCaFile()">
                <fa icon="download" class="button-icon-wrapped" /> CA Cert
              </a>
              <a class="btn mb-2 ms-3 px-2" @click="downloadCertFile()">
                <fa icon="download" class="button-icon-wrapped" /> GW Cert
              </a>
              <a class="btn mb-2 ms-3 px-2" @click="downloadPrivateKeyFile()">
                <fa icon="download" class="button-icon-wrapped" /> GW Key
              </a>
            </div>
          </div>

          <div class="row mt-3" v-if="certPEM" style="justify-content: center">
            <div class="col-auto" style="text-align:center;min-width:330px;">
              <a class="btn mb-2" @click="lnsToClipboard()">
                <fa icon="copy" class="button-icon-wrapped" /> LNS URL
              </a>
              <a class="btn mb-2 ms-3" @click="cupsToClipboard()">
                <fa icon="copy" class="button-icon-wrapped" /> CUPS URL
              </a>
            </div>
          </div>

          <hr class="my-3" v-if="certPEM" />

          <div class="row" v-if="certPEM" style="justify-content: center">
            <div class="col-auto" style="text-align:center;min-width:330px;">
              <span class="status-danger">These files are <i>very</i> sensitive; delete from your PC after the gateway is configured.</span>
            </div>
          </div>
        </div>

      </template>
      <template v-slot:footer>
        <div class="row">
          <div class="col buttons-right" style="width:100%">
            <a class="btn btn-blue" @click.prevent="close()">Close</a>
          </div>
        </div>
      </template>
    </modal>
  </transition>
</template>

<script>
import Modal from '@/components/Modal.vue'
import FleetDataService from '@/services/fleet/FleetDataService'
import HelpContent from '@/services/HelpContent'

export default {
  name: 'modal-edit-site-location',
  data () {
    return {
      isBlocking: false,
      code: '',
      certPEM: '',
      privateKey: '',
      caCert: '',
      cupsURL: '',
      lnsURL: '',
      isLoading: false
    }
  },
  props: ['site', 'gatewayEui'],
  components: { Modal },
  methods: {
    requestAccess () {
      this.isLoading = true
      FleetDataService.requestGatewayCredentials(this.gatewayEui)
        .then(response => {
          if (response.data.message) {
            HelpContent.setFlashMessage(response.data.message)
          }
        })
        .catch(e => {
          let message = 'Unable to request Gateway Credentials'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    verifyAccess () {
      this.isLoading = true
      const code = this.code
      this.reset()
      FleetDataService.getGatewayCredentials(this.gatewayEui, code)
        .then(response => {
          this.certPEM = response.data.CertificatePem
          this.privateKey = response.data.KeyPair.PrivateKey
          this.caCert = response.data.CertificateAuthority
          this.lnsURL = response.data.LNSEndpointURL
          this.cupsURL = response.data.CUPSEndpointURL
        })
        .catch(e => {
          let message = 'Unable to Verify Access Code'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    downloadCertFile () {
      const url = window.URL.createObjectURL(new Blob([this.certPEM]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `gw-${this.gatewayEui}-cert.pem`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    downloadPrivateKeyFile () {
      const url = window.URL.createObjectURL(new Blob([this.privateKey]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `gw-${this.gatewayEui}-priv.key`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    downloadCaFile () {
      const url = window.URL.createObjectURL(new Blob([this.caCert]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'aws-agrology-ca.pem')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    cupsToClipboard () {
      navigator.clipboard.writeText(this.cupsURL)
      HelpContent.setTimedFlashMessage('CUPS URL Copied to clipboard')
    },
    lnsToClipboard () {
      navigator.clipboard.writeText(this.lnsURL)
      HelpContent.setTimedFlashMessage('LNS URL Copied to clipboard')
    },
    reset () {
      this.certPEM = ''
      this.privateKey = ''
      this.caCert = ''
      this.cupsURL = ''
      this.lnsURL = ''
      this.code = ''
    },
    show () {
      this.reset()
      this.isBlocking = true
    },
    close () {
      this.isBlocking = false
    }
  }
}
</script>
