<template>
  <transition name="modal">
    <modal v-if="isBlocking" @close="close()" sz="sm">

        <template v-slot:header>
        Payload Decoder
        </template>

        <template v-slot:body>

          <div class="row decwig-row">
            <div class="col-auto decwig-row-header">
              Format
            </div>
            <div class="col">
              <select class="form-select" v-model="format" style="width:100%" >
                <option value="v2">Agrology V2</option>
                <option value="v1_ground">Agrology V1</option>
                <option value="v0_ghg">Agrology GHG v0</option>
                <option value="v0_5ghg">Agrology GHG v0.5</option>
                <option value="pm">DecentLab PM</option>
                <option value="trs12">DecentLab TRS12</option>
                <option value="trs21">DecentLab TRS21</option>
                <option value="lp8p">DecentLab LP8P</option>
                <option value="smtp">DecentLab SMTP</option>
                <option value="dws">DecentLab DWS</option>
                <option value="s2103">Seeed S2103</option>
                <option value="oyster3">Digital Matter Oyster3</option>
              </select>
            </div>
          </div>

          <div class="row decwig-row">
            <div class="col-auto decwig-row-header">
              Payload
            </div>
            <div class="col">
              <input type="text" class="form-input" v-model="payload" style="width:100%;" ref="payload"/>
              <br />
            </div>
          </div>

          <div class="row decwig-row decwig-row-compact" v-for="(key, value) in parsedPayload" :value="key" :key="value" >
            <div class="col-auto decwig-row-data-header">
              <span>{{ value }}</span>
            </div>
            <div class="col-auto">
              <span>{{ key.Value }}</span>
              <span class="font-reduce-2 subtle" v-if="key.Units">&nbsp;{{ key.Units }}</span>
            </div>
          </div>

        </template>

    </modal>
  </transition>
</template>

<script>
import Modal from '@/components/Modal.vue'
import DecoderService from '@/services/decoders/DecoderService'

export default {
  data () {
    return {
      isBlocking: false,
      format: '',
      payload: ''
    }
  },
  components: { Modal },
  methods: {
    show () {
      this.isBlocking = true
      this.$nextTick(function () {
        this.$refs.payload.focus()
      })
    },
    close () {
      this.isBlocking = false
    }
  },
  computed: {
    parsedPayload () {
      if (this.payload !== '' && this.format !== '') {
        const out = DecoderService.decode(this.format, this.payload)
        return out
      }
      return {}
    }
  }
}
</script>
